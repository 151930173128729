/* You can add global styles to this file, and also import other style files */

@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap");
@font-face {
  font-family: "Avenir Book";
  src: url("./assets/fonts/avenir.eot");
  src: url("./assets/fonts/avenir.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/avenir.woff2") format("woff2"),
    url("./assets/fonts/avenir.woff") format("woff"),
    url("./assets/fonts/avenir.ttf") format("truetype"),
    url("./assets/fonts/avenir.svg#Avenir Book") format("svg");
}

@font-face {
  font-family: "Avenir LT Std";
  src: url("./assets/fonts/AvenirLTStd-Book.eot");
  src: url("./assets/fonts/AvenirLTStd-Book.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/AvenirLTStd-Book.woff2") format("woff2"),
    url("./assets/fonts/AvenirLTStd-Book.woff") format("woff"),
    url("./assets/fonts/AvenirLTStd-Book.ttf") format("truetype"),
    url("./assets/fonts/AvenirLTStd-Book.svg#AvenirLTStd-Book") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Avenir LT Std";
  src: url("./assets/fonts/AvenirLTStd-Black.eot");
  src: url("./assets/fonts/AvenirLTStd-Black.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/AvenirLTStd-Black.woff2") format("woff2"),
    url("./assets/fonts/AvenirLTStd-Black.woff") format("woff"),
    url("./assets/fonts/AvenirLTStd-Black.ttf") format("truetype"),
    url("./assets/fonts/AvenirLTStd-Black.svg#AvenirLTStd-Black") format("svg");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Avenir LT Std";
  src: url("./assets/fonts/AvenirLTStd-Roman.eot");
  src: url("./assets/fonts/AvenirLTStd-Roman.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/AvenirLTStd-Roman.woff2") format("woff2"),
    url("./assets/fonts/AvenirLTStd-Roman.woff") format("woff"),
    url("./assets/fonts/AvenirLTStd-Roman.ttf") format("truetype"),
    url("./assets/fonts/AvenirLTStd-Roman.svg#AvenirLTStd-Roman") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Avenir";
  src: url("./assets/fonts/Avenir-Heavy.eot");
  src: url("./assets/fonts/Avenir-Heavy.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Avenir-Heavy.woff2") format("woff2"),
    url("./assets/fonts/Avenir-Heavy.woff") format("woff"),
    url("./assets/fonts/Avenir-Heavy.ttf") format("truetype"),
    url("./assets/fonts/Avenir-Heavy.svg#Avenir-Heavy") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir";
  src: url("./assets/fonts/Avenir-Medium.eot");
  src: url("./assets/fonts/Avenir-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Avenir-Medium.woff2") format("woff2"),
    url("./assets/fonts/Avenir-Medium.woff") format("woff"),
    url("./assets/fonts/Avenir-Medium.ttf") format("truetype"),
    url("./assets/fonts/Avenir-Medium.svg#Avenir-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'fsme';
  src: url('./assets/fonts/fsme-bold-1.eot');
  src: url('./assets/fonts/fsme-bold-1.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/fsme-bold-1.woff2') format('woff2'),
       url('./assets/fonts/fsme-bold-1.woff') format('woff'),
       url('./assets/fonts/fsme-bold-1.ttf')  format('truetype'),
       url('./assets/fonts/fsme-bold-1.svg#fs me') format('svg');
}


@font-face {
  font-family: 'fsme-regular';
  src: url('./assets/fonts/fsme-regular.eot');
  src: url('./assets/fonts/fsme-regular.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/fsme-regular.woff2') format('woff2'),
       url('./assets/fonts/fsme-regular.woff') format('woff'),
       url('./assets/fonts/fsme-regular.ttf')  format('truetype'),
       url('./assets/fonts/fsme-regular.svg#fs me') format('svg');
}

@font-face {
  font-family: 'clever bold';
  src: url('./assets/fonts/project-clever-bold.eot');
  src: url('./assets/fonts/project-clever-bold.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/project-clever-bold.woff2') format('woff2'),
       url('./assets/fonts/project-clever-bold.woff') format('woff'),
       url('./assets/fonts/project-clever-bold.ttf')  format('truetype'),
       url('./assets/fonts/project-clever-bold.svg#clever bold') format('svg');
}
/* .owl-carousel .owl-item .quote-img img {
  width: auto;
  max-width: 100%;
}

.owl-carousel .owl-item .client-review-pic img {
  width: auto;
  max-width: 100%;
} */

mat-spinner {
  margin: 0 auto;
}
body {
  /* font-family: "Avenir Book"; */
  font-weight: 400;
}

a {
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s;
}

a:hover {
  text-decoration: none;
  transition: all 0.3s;
}

.mat-dropmenu-header {
  padding: 0px 8px !important;
}

.mat-dropmenu-header a {
  padding: 10px 10px !important;
}

.inner-wrapper {
  width: 85%;
  margin: auto;
  max-width: 1633px;
}

.default-color {
  color: #f97267;
}

.default-bg {
  background: #f97267;
  color: #fff;
}

.mat-badge-content {
  background: #f97267;
}

.default-btn-css {
  background: transparent !important;
  box-shadow: none !important;
  outline: none !important;
  padding: 0 !important;
  border: 0;
  cursor: pointer;
}

.theme-btn {
  background-color: #5AAF96 !important;
  color: #ffffff !important;
  font-family: "Avenir Book";
  font-size: 17px;
  font-weight: 900;
  letter-spacing: 1px;
  transition: all 0.4s;
}

.theme-btn:hover {
  box-shadow: 3px 4px 5px rgba(249, 114, 103, 0.32);
  transition: all 0.4s;
}

mat-card {
  box-shadow: none !important;
  background: transparent !important;
  padding: 0;
}

/* header */

header .header-row {
  height: auto;
  justify-content: space-between;
}

header {
  background: #fff;
  padding: 15px 0;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.06);
}

header mat-toolbar {
  background: #fff;
}

header .mat-toolbar-multiple-rows {
  background: #fff;
}

.menu-list ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center;
}

.menu-list ul li {
  margin-right: 25px;
}

.menu-list ul li:last-child {
  margin-right: 0px;
}

.menu-list ul li a {
  color: #000000;
  font-family: "Avenir LT Std";
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0.4px;
  line-height: normal;
  display: inline-block;
  text-transform: uppercase;
  padding: 5px 10px;
  text-decoration: none;
  transition: all 0.3s;
}

.menu-list ul li a:hover {
  color: #f97267;
  transition: all 0.3s;
}

.user-stat-btn,
.user-stat-btn:hover,
.user-stat-btn:focus {
  padding: 0;
  outline: 0 !important;
  box-shadow: none !important;
  background: transparent !important;
  border: 0;
  cursor: pointer;
}

.user-pic img {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  position: relative;
}

.user-pic span.down-soft {
  position: absolute;
  right: -3px;
  bottom: 3px;
}

.user-pic span.down-soft i {
  line-height: 8px;
  font-size: 10px;
  width: 17px;
  height: 17px;
  border: 2px solid #ffffff;
  background-color: #9f9f9f;
  color: #fff;
  border-radius: 50%;
}

.mat-droplist {
  padding: 10px 20px;
  transition: all 0.3s;
  color: #737373 !important;
  font-family: "Avenir LT Std";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.mat-droplist img {
  margin-right: 15px;
}

.mat-droplist:hover {
  color: #f97267 !important;
  transition: all 0.3s;
}

li.cart-list a .cart-span {
  width: 61px;
  height: 55px;
  border: 1px solid #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.default-btn-css
  .mat-badge-medium.mat-badge-overlap.mat-badge-after
  .mat-badge-content {
  right: -8px;
  top: -15px;
}

.theme-btn.checkout-btn {
  width: 151px;
  height: 55px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.theme-btn.checkout-btn {
  font-weight: 500 !important;
  letter-spacing: 1px !important;
}

.right-menu {
  display: flex;
  align-items: center;
}

.menu-item.user-menu {
  margin-left: 20px;
}

.responsive-menu-btn {
  display: none !important;
}

/* end header */

.mat-progress-spinner.loader {
  color: red;
  margin: auto;
}

.mat-progress-spinner.loader circle,
.mat-spinner.loader circle {
  stroke: #f97267;
}


.loader-section {
  text-align: center;
}

.loader-section h5 {
  font-size: 20px;
  margin-top: 30px;
  color: #f97267;
  font-family: "Avenir Book";
  font-weight: 600;
  letter-spacing: 1px;
}

/********** User Module Css ***********/

/* homepage */

.banner-section {
  background-color: #0c3a70;
}

.banner-inner {
  width: 53%;
  margin: auto;
  padding: 75px 0 55px;
}

h5.banner-tag {
  color: #ffffff;
  font-family: "Avenir Book";
  font-size: 17px;
  font-weight: 500;
  text-align: left;
  background-color: #f97267;
  display: inline-block;
  padding: 5px 16px;
  margin-bottom: 20px;
}

.banner-img img {
  max-width: 100%;
}

.banner-content h1 {
  color: #ffffff;
  font-family: "Avenir LT Std";
  font-size: 46px;
  font-weight: 900;
  line-height: 1.3;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 25px;
}

.banner-content h3 {
  color: #ffffff;
  font-size: 30px;
  font-weight: 500;
  line-height: 40px;
  text-align: left;
  margin-bottom: 35px;
}

.banner-content a.banner-shop-btn {
  color: #ffffff;
  font-family: "Avenir LT Std";
  font-size: 15px;
  font-weight: 900;
  letter-spacing: 0.88px;
  text-transform: uppercase;
  width: 155px;
  height: 55px;
  border: 2px solid #ffffff;
  background-color: rgba(255, 227, 109, 0);
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-img {
  text-align: right;
  padding-left: 50px;
}

/* bundle section */

.pink-bg {
  background-color: #f97267;
}

a.bundle-shop-btn.pink {
  color: #e84b57;
}

.purple-bg {
  background-color: #8878ee;
}

a.bundle-shop-btn.purple {
  color: #0c3a70;
}

.lightblue-bg {
  background-color: #54d0ef;
}

a.bundle-shop-btn.lightblue {
  color: #54d0ef;
}

.yellow-bg {
  background-color: #ffe36d;
}

a.bundle-shop-btn.yellow {
  color: #ffe36d;
  background-color: #000000;
}

.bundle-section {
  padding: 70px 0 45px;
}

.bundle-section mat-card {
  margin-bottom: 25px;
  margin-right: 1.6%;
  padding: 0;
}

.membership-wrapper mat-card:nth-child(2) p.plan-noti {
  color: #54d0ef;
}

.bundle-book {
  padding: 45px 35px 25px;
  color: #ffffff;
  position: relative;
  background-color: #f97267;
  min-height: 385px;
}

.bundle-book.black-txt {
  color: #000000;
}

.bundle-section mat-card:nth-child(even) {
  margin-right: 0%;
}

.bundle-img {
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bundle-img img {
  max-width: 100%;
}

.bundle-book h3 {
  font-family: "Avenir";
  font-size: 34px;
  font-weight: 800;
  margin-bottom: 20px;
  letter-spacing: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 82px;
}

h4.bundle-price {
  font-family: "Avenir";
  font-size: 27px;
  font-weight: 900;
  margin-bottom: 15px;
}

a.bundle-shop-btn {
  width: 178px;
  height: 49px;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 900;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Avenir";
  color: #808080;
}

.bundle-ribon {
  background: url(./assets/img/bundle-ribon.png) no-repeat;
  padding: 14px 30px;
  background-size: cover;
  height: 62px;
  margin-bottom: 30px;
  margin-left: -42px;
}

.bundle-ribon p {
  color: #ffffff;
  font-family: "Avenir Book";
  font-size: 20px;
  font-weight: 800;
  margin: 0;
  line-height: 20px;
  letter-spacing: 1px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* membership plan */

.membership-plan-section {
  background-color: #fafafa;
  padding: 85px 0;
}

.membership-wrapper {
  width: 90%;
  max-width: 1180px;
  margin: auto;
}

mat-card.mat-heading {
  margin-bottom: 50px;
}

.mat-heading h2.heading {
  color: #000000;
  font-family: "Avenir";
  font-size: 40px;
  font-weight: 800;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
}

.membership-list {
  text-align: center;
  box-shadow: 0 8px 17px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding-bottom: 45px;
  transition: all 0.3s;
}

.membership-list:hover {
  box-shadow: 0 8px 17px rgb(0 0 0 / 21%);
  transition: all 0.3s;
}

.membership-list mat-card-header {
  height: 93px;
  background-color: #f97267;
  display: flex;
  align-items: center;
  justify-content: center;
}

.membership-list mat-card-content {
  padding: 0px 25px;
  margin-bottom: 0px !important;
}

.membership-list .memberlist-title {
  color: #ffffff;
  font-family: "Avenir";
  font-size: 24px;
  font-weight: 900;
  margin: 0 !important;
  padding: 0;
  letter-spacing: normal;
}

.membership-list .mat-grey-body {
  background-color: #f8f8f8;
  padding: 25px 20px 30px;
  margin-top: 15px !important;
  position: relative;
}

.membership-list .membership-price {
  color: #000000;
  font-family: "Avenir";
  font-size: 54px;
  font-weight: 800;
  letter-spacing: normal;
  line-height: 43px;
  margin-bottom: 9px;
}

.membership-list .plan-limit {
  color: #484848;
  font-family: "Avenir";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: normal;
  /* margin-bottom: 20px; */
  margin-bottom: 0px;
}

.membership-list .plan-noti {
  color: #f97267;
  font-family: "Avenir";
  font-size: 21px;
  font-weight: 800;
  letter-spacing: 0px;
  margin-bottom: 0px;
}

.membership-list mat-card-content ul {
  padding: 15px 0;
  margin: 0;
  list-style: none;
}

.membership-list mat-card-content ul li {
  color: #484848;
  font-family: "Avenir";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 30px;
  padding: 20px 30px;
  border-bottom: 1px dashed #d8d8d8;
  font-style: normal;
}

.membership-list mat-card-content ul li:last-child {
  border-bottom: 0px dashed #d8d8d8;
}

.membership-list mat-card-actions {
  margin: 0 !important;
  padding: 0 25px;
}

.membership-btn {
  background-color: #54d0ef;
  width: 100%;
  height: 55px;
  color: #ffffff;
  font-family: "Avenir";
  font-size: 17px;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: all 0.3s;
}

.membership-btn:hover {
  transition: all 0.3s;
  background: #f97267;
}

.membership-wrapper mat-card:nth-child(2) .membership-btn {
  background: #f97267;
}

.membership-wrapper mat-card:nth-child(2) .mat-card-header {
  background: #54d0ef;
}

/* end homepage */

/* Login, Signup, forget password, reset password Page */

.login-section {
  padding: 150px 0;
  background: #fafafa;
}

.login-body {
  width: 500px;
  margin: auto;
  box-shadow: 0 9px 20px 5px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  background-color: #ffffff;
  padding: 40px 50px;
  text-align: center;
}

.login-body mat-form-field.form-field {
  display: block;
}

.login-body h2 {
  color: #000000;
  font-family: "Avenir";
  font-weight: 900;
  font-size: 30px;
  line-height: 28px;
  margin-bottom: 15px;
}

.login-body p {
  text-align: center;
  color: #616161;
  font-family: 'fsme-regular';
  font-size: 24px;
  font-weight: 400;
}

.login-body .form-group {
  text-align: left;
  margin-bottom: 20px;
}

.login-body .form-group label {
  margin-bottom: 10px;
  line-height: normal;
  text-align: left;
  color: #003430;
  font-family: 'fsme';
  font-size: 19px;
}

.login-body .form-group .form-control, .login-body .form-group select.form-control {
  padding: 10px 20px;
  height: 74px;
  border-radius: 10px;
  border: 2px solid #e5ebf3;
  background-color: #ffffff;
  color: #576379;
  font-family: 'fsme-regular';
  font-size: 18px;
  font-weight: 400;
}
.click-login h6 {
  color: #003430;
  font-family: 'fsme-regular';
  font-size: 18px;
  font-weight: 400;
}

.click-login h6 a {
  color: #003430;
  font-family: 'fsme';
  font-weight: 700;
  font-size: 18px;
}

.login-body .form-group .input-group .eye-icon {
  position: absolute;
  right: 11px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  z-index: 9;
  cursor: pointer;
}

.login-body .form-group .input-group .form-control {
  padding-right: 40px !important;
}

.login-body .mat-checkbox-inner-container {
  width: 23px;
  height: 23px;
  box-shadow: inset 0 1px 5px 1px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  border: 1px solid #bbbbbb;
  background-color: #ffffff;
  outline: none;
}

.login-body .button-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login-body label.mat-checkbox-layout {
  margin: 0;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent
  .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent
  .mat-ripple-element {
  background: #f97267;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #f97267;
}

.login-body span.mat-checkbox-label {
  color: #000000;
  font-family: "Avenir Book";
  font-size: 14px;
  font-weight: 600;
}

.login-body a {
  color: #000000;
  font-family: "Avenir Book";
  font-size: 14px;
  font-weight: 600;
}

.login-body a:hover {
  color: #f97267;
}

.login-body span.mat-checkbox-frame {
  border: 0px;
}

.login-body .submit-btn {
  width: 100%;
  border-radius: 10px;
  background-color: #f0a5a5;
  color: #ffffff;
  font-family: 'fsme';
  font-size: 27px;
  font-weight: 700;
  padding: 19px 0px;
}

.login-footer-btn {
  color: #000000;
  font-family: "Avenir Book";
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}

.login-footer-btn a {
  color: #f97267;
  font-family: "Avenir Book";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.4px;
}

.signup-section {
  padding: 110px 0;
  background: #fafafa;
}

.login-body span.mat-checkbox-label a {
  color: #f97267;
}

.forgot-password-section {
  padding: 130px 0;
  background: #fafafa;
}

.forgot-password-section .login-body {
  padding: 45px 50px 70px;
}

.reset-password-section {
  padding: 150px 0;
  background: #fafafa;
}

.reset-password-section .login-body {
  padding: 50px 50px;
}

/* end Login, Signup, forget password, reset password Page */

/* view and edit profile */

.profile-section {
  padding: 75px 0;
}

.user-profile {
  border: 1px solid #eaeaea;
  background-color: #ffffff;
  padding: 50px 45px;
  text-align: center;
}

.profile-pic {
  margin-bottom: 25px;
  position: relative;
}

.user-profile img {
  width: 198px;
  height: 198px;
  border-radius: 50%;
}

.user-profile h3 {
  color: #000000;
  font-family: "Avenir Book";
  font-size: 27px;
  font-weight: 800;
  line-height: 28px;
  margin-bottom: 10px;
}

.user-profile p {
  color: #666666;
  font-family: "Avenir";
  font-size: 17px;
  font-weight: 500;
  line-height: 21px;
}

.user-details-field {
  border: 1px solid #eaeaea;
  background-color: #ffffff;
  margin-bottom: 25px;
  padding: 30px 25px;
}

.user-details-field h3 {
  color: #000000;
  font-family: "Avenir";
  font-size: 20px;
  font-weight: 900;
  line-height: 28px;
  margin-bottom: 15px;
}

.user-details-field p {
  color: #666666;
  font-family: "Avenir";
  font-size: 17px;
  font-weight: 500;
  line-height: 21px;
  margin-bottom: 10px;
}

.user-details-field p b {
  color: #000000;
}

.user-details-field .membership-table {
  width: 100%;
}

.user-details-field .membership-table table {
  width: 100%;
}

.user-details-field .membership-table table thead {
  height: 55px;
  background-color: #fafafa;
}

.user-details-field .membership-table table th {
  color: #666666;
  font-family: "Avenir";
  font-size: 17px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  padding: 15px 15px;
}

.user-details-field .membership-table table td {
  color: #000000;
  font-family: "Avenir Book";
  font-size: 17px;
  font-weight: 800;
  line-height: 21px;
  text-align: center;
  padding: 20px 15px;
}

.membership-btn-group {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
}

.membership-btn-group a {
  margin: 0px 6px;
  color: #f97267 !important;
  font-family: "Avenir LT Std";
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  text-decoration: underline !important;
}

.membership-btn-group a.membership-redbtn {
  color: #f97267;
}

table.payment-table {
  max-width: 663px;
  width: 100%;
  margin-bottom: 10px;
}

table.payment-table tr {
  border: 1px solid #fad1d1;
  background-color: #fafafa;
}

table.payment-table tr.active {
  border: 1px solid #fad1d1;
  background-color: #fff7f7;
}

table.payment-table td {
  padding: 15px 15px;
  margin-bottom: 10px;
  color: #000000;
  font-family: "Avenir Book";
  font-size: 13px;
  font-weight: 800;
}

table.payment-table td img {
  margin-right: 8px;
}

table.payment-table td:first-child {
  width: 37px;
  padding-right: 0;
  padding-left: 17px;
}

table.payment-table td:last-child {
  width: 80px;
}

.add-card-btn {
  color: #f97267;
  font-family: "Avenir Book";
  font-size: 13px;
  font-weight: 800;
  display: flex;
  align-items: center;
  padding: 0;
}

.add-card-btn i {
  margin-right: 5px;
  font-size: 18px;
}

.profile-form .form-group .form-control {
  height: 45px;
  border-radius: 3px;
  border: 1px solid #d1d1d1;
  background-color: #ffffff;
  color: #999999;
  color: #161616;
  font-family: "Avenir LT Std";
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 4px;
}

.profile-form .form-group {
  margin-bottom: 20px !important;
}

mat-hint {
  color: #f97267 !important;
  font-family: "Avenir";
}

.profile-form mat-card {
  padding: 0;
}

.profile-form .form-group label {
  color: #000000;
  font-family: "Avenir Book";
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 13px;
}

.profile-form h3 {
  color: #000000;
  font-family: "Avenir Book";
  font-size: 20px;
  font-weight: 800;
  line-height: 28px;
  margin-bottom: 25px;
}

.profile-form .form-group textarea.form-control {
  height: 100px;
  resize: none;
}

.upload-profile-pic {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.upload-profile-pic label {
  display: inline-block;
  color: white;
  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
  margin: 0;
}

.upload-profile-pic img {
  width: 45px;
  height: 45px;
}

/* end view and edit profile */

/* success order */

section.success-order-section {
  padding: 90px 0;
  text-align: center;
  border-bottom: 1px solid #f0f0f0;
}

.success-order-field {
  width: 100%;
  max-width: 620px;
  margin: auto;
}

.success-order-field img {
  margin-bottom: 40px;
}

.success-order-field h1 {
  color: #000000;
  font-family: "Avenir";
  font-size: 46px;
  font-weight: 800;
  line-height: 50px;
  margin-bottom: 35px;
}

.success-order-field p {
  color: #666666;
  font-family: "Avenir";
  font-size: 17px;
  font-weight: 500;
  line-height: 21px;
  margin-bottom: 40px;
}

.success-order-field a {
  width: 451px;
  height: 55px;
  box-shadow: 3px 4px 5px rgba(249, 114, 103, 0.32);
  background-color: #f97267;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-family: "Avenir";
  font-size: 17px;
  font-weight: 800;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.success-order-section mat-card {
  padding: 0;
}

h2.section-title {
  color: #000000;
  font-family: "Avenir";
  font-size: 40px;
  font-weight: 800;
  text-align: center;
  margin-bottom: 40px;
}

/* end success-order */

/* bundle details */

.bundle-big-img {
  border: 1px solid #eeeeee;
  background-color: #ffffff;
  padding: 50px 110px 70px;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #f97267;
}

.owl-dots.ng-star-inserted {
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
}

.shop-detail-section {
  padding: 50px 0;
}

.bundle-full-description h2 {
  color: #000000;
  font-family: "Avenir";
  font-size: 27px;
  font-weight: 800;
  line-height: 31px;
}

.bundle-full-description ul {
  list-style: none;
  padding: 0;
}

.bundle-full-description ul li {
  margin-bottom: 5px;
}

.bundle-full-description p,
.bundle-full-description ul li,
.description {
  color: #666666;
  font-family: "Avenir";
  font-size: 16px;
  font-weight: 500;
}

.bundle-full-description p.author {
  margin-bottom: 25px;
}

.bundle-full-description h3.price {
  color: #f97267;
  font-family: "Avenir Book";
  font-size: 30px;
  font-weight: 800;
  line-height: 26px;
  margin-bottom: 20px;
}

.description h4 {
  color: #000000;
  font-family: "Avenir Book";
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 15px;
}

.cart-wishlist-field {
  padding: 20px 0;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  align-items: center;
}

.description {
  padding-bottom: 15px;
}

.quantity-btn {
  width: 110px;
  height: 50px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  border: 2px solid #eeeeee;
  margin-right: 5px;
}

.quantity-btn .button-container .btn {
  height: 46px;
  font-size: 22px;
  font-family: "Avenir Book";
  color: #bbbbbb;
  text-align: center;
  outline: none;
  box-shadow: none;
  padding: 0 13px;
  background: transparent;
  font-weight: 900;
}

.quantity-btn .button-container .btn {
  line-height: 0;
}

.quantity-btn input.qty {
  height: 46px;
  text-align: center;
  color: #000000;
  font-family: "Avenir Book";
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  width: 30px;
  border: 0px;
  outline: none;
}

.add-cart-btn img {
  margin-right: 10px;
}

.add-cart-btn {
  height: 50px;
  font-family: 'fsme';
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
}
.page-header.pupils-align label {
  color: #003430;
  font-family: 'fsme-regular';
  font-size: 17px;
  margin: 0;
}

.wishlist-btn {
  border: 2px solid #eeeeee !important;
}

.cart-wishlist-field .btn-field {
  margin-right: 5px;
}

.bundle-briefing {
  padding-top: 20px;
}

.bundle-briefing ul {
  display: flex;
}

.bundle-briefing ul li {
  padding: 0 20px;
  position: relative;
}

.bundle-briefing ul li:after {
  content: "";
  width: 1px;
  height: 42px;
  background: #e5e5e5;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.bundle-briefing ul li p {
  color: #000000;
  font-family: "Avenir Book";
  font-weight: 900;
  text-align: center;
  font-size: 14px;
  line-height: normal;
  margin-bottom: 5px;
}

.bundle-briefing ul li:last-child:after {
  display: none;
}

.bundle-briefing ul li p.title {
  color: #666666;
  margin-bottom: 10px;
  font-weight: 500;
  font-family: "Avenir";
}

.review-details {
  padding: 30px 20px;
  height: 390px;
  box-shadow: 0 8px 17px rgb(0 0 0 / 5%);
  background-color: #ffffff;
  margin: 20px;
  position: relative;
}

.client-review-pic {
  position: relative;
  margin-bottom: 25px;
}

.client-review-pic:before {
  content: "";
  background-color: #ececec;
  position: absolute;
  width: 100%;
  height: 1px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.quote-img {
  position: absolute;
  top: 3px;
  right: -65px;
  left: 0;
}

.client-review-pic img {
  position: relative;
  margin: auto;
}
.owl-carousel .owl-item .client-review-pic img {
  width: auto;
}
.review-details p {
  color: #000000;
  font-family: "Avenir Book";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.21px;
  line-height: 27px;
  text-align: center;
}

section.client-testimonial-section {
  padding: 20px 0;
}

.client-testimonial-section .inner-wrapper {
  max-width: 1220px;
}

.revierclient-detial h3 {
  color: #1a1a26;
  font-family: "Avenir";
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 23px;
  text-align: center;
  font-size: 20px;
  margin-bottom: 10px;
}

.revierclient-detial p {
  color: #888888;
  font-family: "Avenir Book";
  font-size: 17px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 23px;
  text-align: center;
  margin: 0;
}

.revierclient-detial {
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 100%;
  padding: 0 20px;
}

.owl-theme .owl-dots .owl-dot span {
  width: 12px;
  height: 12px;
}

/* end bundle details */

/* checkout page */

.checkout-order {
  border-radius: 5px;
  border: 1px solid #eeeeee;
  background-color: #f6f6f6;
  padding: 20px 25px;
}

.checkout-oder-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cancel-order-btn {
  padding: 0px 10px !important;
  min-width: auto !important;
}

.checkout-oder-list ul li img {
  width: 70px;
  height: 90px;
}

.checkout-oder-list ul li {
  color: #000000;
  font-family: "Avenir Book";
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
}

.checkout-oder-list {
  border-radius: 5px;
  border: 1px solid #eeeeee;
  background-color: #ffffff;
  padding: 15px 20px;
  margin-bottom: 12px;
}

.checkout-oder-list ul li.order-book-img {
  width: 75%;
  padding-right: 15px;
  display: flex;
  align-items: center;
}

.checkout-oder-list ul li.order-book-img span {
  padding-left: 15px;
  word-break: break-all;
}

.checkout-pricing {
  padding-top: 30px;
}

.checkout-pricing ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.checkout-pricing ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  border-top: 1px solid #eeeeee;
}

.checkout-pricing ul li p {
  margin: 0;
  color: #666666;
  font-family: "Avenir";
  font-size: 15px;
  font-weight: 500;
}

.checkout-pricing ul li:last-child {
  padding-bottom: 0;
}

.checkout-pricing ul li p.total-price {
  color: #000000;
  font-family: "Avenir LT Std";
  font-size: 18px;
  font-weight: 800;
}

.payment-method-list {
  padding: 20px 0;
}

.payment-method-list ul {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.payment-method-list ul li {
  color: #8d8d8d;
  font-family: "Avenir Book";
  font-size: 13px;
  font-weight: 800;
  padding-right: 10px;
  letter-spacing: 0.7px;
}

.payment-method-list ul li img {
  width: 70px;
  margin-right: 10px;
}

.user-payment-method.checkout-payment {
  margin-top: 10px;
}

.user-payment-method.checkout-payment h3 {
  color: #000000;
  font-family: "Avenir Book";
  font-size: 13px;
  font-weight: 800;
  margin-bottom: 14px;
}

.checkout-order-section {
  padding-bottom: 25px;
  border-bottom: 2px solid #e0e0e0;
}

.order-placed {
  margin-top: 30px;
  text-align: right;
}

.order-placed-btn {
  width: 222px;
  height: 55px;
  color: #ffffff;
  font-family: "Avenir";
  font-size: 17px;
  font-weight: 800;
  letter-spacing: 1px;
  text-transform: uppercase;
  box-shadow: 3px 4px 5px rgba(249, 114, 103, 0.32);
}

.checkout-order h3 {
  color: #000000;
  font-family: "Avenir";
  font-size: 27px;
  font-weight: 800;
  letter-spacing: normal;
  line-height: 28px;
  text-align: center;
  margin-bottom: 25px;
  margin-top: 7px;
}

.menu-item.user-menu {
  margin-left: 0 !important;
}

/* end checkout page */

.user-profile p {
  overflow-wrap: break-word;
}

/********** User Module Css ***********/

/********** Student Module Css ***********/

/* header with sidebar */

.sidebar-header {
  padding: 18px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  height: 100px;
  box-shadow: 1px 0 0 rgb(0 0 0 / 4%);
  border-bottom: 1px solid #E6E6E6;
}

mat-sidenav.mat-drawer.mat-sidenav {
  width: 30%;
  max-width: 257px;
  position: fixed;
  top: 100px;
  padding-bottom: 100px;
  box-shadow: 1px 0 0 rgb(0 0 0 / 4%);
  background-color: #ffffff;
  border-right: 0;
}

mat-sidenav.mat-drawer.mat-sidenav ::-webkit-scrollbar {
  width: 4px;
}

mat-sidenav.mat-drawer.mat-sidenav ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */

mat-sidenav.mat-drawer.mat-sidenav ::-webkit-scrollbar-thumb {
  background: #888;
}

.mat-sidenav-container.example-sidenav-container {
  min-height: calc(100vh - 100px);
  background-color: #fff;
}

.sidebar-header .header-row {
  /* padding: 0 50px; */
  padding-left: 30px;
}

.sidebar-header .inner-wrapper {
  width: 100%;
  max-width: 100%;
}

.sidebar-header .menu-list ul li:last-child {
  padding: 0 25px;
  border-left: 1px solid #f2f2f2;
  display: flex;
  align-items: center;
}

.dashboard-profile-pic img {
  width: 49px;
  height: 49px;
  border: 3px solid #fff;
  border-radius: 50%;
  margin-right: 15px;
}

.dashboard-profile-pic {
  color: #4a4a4a;
  font-family: "Avenir";
  font-size: 16px;
  font-weight: 800;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}

.dashboard-profile-pic span.down-soft {
  color: #4a4a4a;
  margin-left: 20px;
  position: relative;
  top: -3px;
}

.sidebar-noti ul {
  list-style: none;
  padding: 5px 0px;
  margin: 0;
}

.sidebar-noti ul li,
.sidebar-noti p {
  padding: 1px 0;
  color: #737373 !important;
  font-family: "Avenir LT Std";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 20px;
}

.sidebar-noti p {
  margin-bottom: 5px;
}

.sidenav-area {
  padding: 20px 0;
}

.sidenav-area ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidenav-area ul li a {
  color: #003430 !important;
  font-family: 'fsme-regular';
  font-size: 17px;
  font-weight: 400;
  display: flex;
  padding: 14px 15px;
  border: 1px solid #fff;
  border-radius: 10px;
}
ol.breadcrumb.detals-title {
  background-color: transparent;
  padding: 0;
}

ol.breadcrumb.detals-title li a {
  font-size: 17px;
  line-height: 40.6px;
  text-align: center;
  font-family: fsme;
  color: #003430;
}

.sidenav-area ul li a svg path {
  fill: #003430;
  transition: all 0.3s;
}


.sidenav-area .submenu-dropdown li {
  padding: 0;
}

.sidenav-area .submenu-dropdown li a.dropmenu-list {
  color: #494949 !important;
  border: 0;
  padding: 10px 15px;
}

.sidenav-area ul li a span {
  width: 30px;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.sidenav-area ul li a span img {
  max-width: 100%;
}

.sidenav-area ul li {
  margin-bottom: 10px;
  padding: 0 10px;
}

.sidenav-area ul li:hover a,
.sidenav-area ul li.active a {
  background-color: #5AAF96;
  color: #FFFFFF !important;
    border: 1px solid #5AAF96;
}

.sidenav-area ul li:hover a svg path,
.sidenav-area ul li.active a svg path {
  fill: #FFFFFF;
  transition: all 0.3s;
}

.sidebar-content {
  padding: 0px 40px;
  overflow: hidden !important;
  padding-top: 90px;
  margin-top: 35px;
  margin-bottom: 35px;
  width: calc(100% - 257px) !important;
  
}

.sidebar-footer h4 {
  font-family: 'fsme';
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 5px;
  color: #003430;
}

.sidebar-footer p {
  font-family: 'fsme-regular';
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
  color: #003430;
}

.sidebar-footer {
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  
}
.logout-fixed a.sidebar-btn {
  position: relative;
  bottom: 30px;
}
.app-sidebar-footer {
  padding: 20px 20px;
}
li.logout-fixed {
  border-bottom: 1px solid #a4a4a4;
}

/* end header with sidebar */

/* dashboard css */

.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 30px;
}

.page-header h2 {
  color: #003430;
  font-family: 'fsme';
  font-size: 26px;
  margin: 0;
}
.comments-radio-button label {
  color: #000000;
  font-family: "fsme";
  font-size: 20px;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  text-align: left;
  margin-right: 30px;
}

.comments-radio-button {
  margin-bottom: 20px;
}

.page-header p {
  color: #606060;
  font-family: "Avenir LT Std";
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
  margin: 0;
}
.comment_section12 .filter-section input {
  height: 57px;
  border-radius: 8px;
  border: 1px solid #d4d4d4;
  background-color: #ffffff;
  color: #999999;
  font-family: 'fsme-regular';
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
}

.dashboard-stats {
  margin-bottom: 10px;
}

.dashboard-stats mat-card {
  height: 150px;
  box-shadow: 0 5px 20px rgb(0 0 0 / 4%) !important;
  background-color: #ffffff !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 40px !important;
  margin-right: 2.668%;
  margin-bottom: 20px;
}

.dashboard-stats mat-card:nth-child(4),
.dashboard-stats mat-card:nth-child(8),
.dashboard-stats mat-card:nth-child(12) {
  margin-right: 0%;
}

.dashboard-stats-card {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard-stats-card .statscard-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
}

.dashboard-stats-card .statscard-content p {
  color: #003430;
  font-family: 'fsme-regular';
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.dashboard-stats-card .statscard-content h3 {
  color: #003430;
  font-family: 'fsme';
  font-size: 30px;
  font-weight: 800;
  margin: 0;
}

.buy-book-section {
  margin-bottom: 20px;
}

.buy-book-section mat-card {
  background-color: #ffffff !important;
  margin-right: 2%;
  margin-bottom: 20px;
  padding: 30px 25px;
  text-align: center;
  border: 1px solid #eeeeee;
}

.buy-book-img {
  width: 127px;
  height: 147px;
  box-shadow: 0 7px 4px rgba(0, 0, 0, 0.08);
  border: 1px solid #dfdfdf;
  background-color: #ffffff;
  padding: 10px 10px;
  margin: auto;
  margin-bottom: 30px;
}

.buy-book-img img {
  width: 100%;
  height: 100%;
}

.buy-book-section mat-card:nth-child(5n) {
  margin-right: 0;
}

.section-cat-heading {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 25px;
}

.section-cat-heading h4 {
  color: #161616;
  font-family: "Avenir Book";
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0.33px;
  margin-bottom: 0;
}

.section-cat-heading .viewall-btn {
  color: #161616;
  font-family: "Avenir LT Std";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.27px;
  text-decoration: underline;
  padding: 0;
  margin: 0;
  line-height: initial;
  background: #fafafa;
  outline: none;
  box-shadow: none;
  transition: all 0.4s;
}

.section-cat-heading .viewall-btn:hover {
  background: #fafafa;
  outline: none;
  box-shadow: none;
  box-shadow: none;
  color: #f97267;
  transition: all 0.4s;
}

.buy-book-content h3 {
  color: #000000;
  font-family: "Avenir Book";
  font-size: 18px;
  font-weight: 800;
}

.buy-book-content h4 {
  margin-bottom: 12px;
  font-family: "Avenir";
}

.buy-book-content h4 .final-price {
  color: #f97267;
  font-family: "Avenir";
  font-size: 20px;
  font-weight: 800;
  margin-right: 7px;
}

.buy-book-content h4 del {
  color: #b8b8b8;
  font-family: "Avenir";
  font-size: 15px;
  font-weight: 500;
  text-decoration: line-through;
  margin-right: 7px;
}

.buy-book-content h4 .discount {
  color: #f97267;
  font-family: "Avenir";
  font-size: 15px;
  font-weight: 500;
}

.buy-book-btn {
  width: 100%;
  height: 43px;
  background-color: #b2b2b2;
  color: #ffffff;
  font-family: "Avenir Book";
  font-size: 13px;
  font-weight: 900;
  letter-spacing: 1.767px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s;
  outline: none !important;
}

.buy-book-btn img {
  margin-right: 10px;
}

.buy-book-btn:hover {
  background-color: #f97267;
  transition: all 0.4s;
}

.buy-wishlist-btn {
  outline: none !important;
  padding: 0 !important;
  min-width: auto !important;
  background: transparent !important;
  transition: all 0.4s;
}

.buy-wishlist-btn:hover {
  background: transparent !important;
  box-shadow: none !important;
  color: #f97267;
  outline: none !important;
  transition: all 0.4s;
}

.wishlist-icon {
  position: absolute;
  top: 15px;
  right: 20px;
}

.buy-bundle-section.bundle-section mat-card:nth-child(3),
.buy-bundle-section.bundle-section mat-card:nth-child(6),
.buy-bundle-section.bundle-section mat-card:nth-child(9) {
  margin-right: 0 !important;
}

.buy-bundle-section.bundle-section mat-card {
  margin-right: 2% !important;
}

.buy-bundle-section .bundle-book h3 {
  font-size: 30px;
}

.buy-bundle-section .bundle-img {
  padding-left: 15px;
}

.buy-bundle-section .bundle-ribon {
  background: url(assets/img/bundle-ribbon1.png) no-repeat;
  width: 266px;
  height: 59px;
}

.buy-bundle-section .bundle-content {
  padding-top: 10px;
}

section.bundle-section.buy-bundle-section {
  padding: 0 !important;
}

/* end dashboard css */

/* dashboard checkout css */

.dashboard-profile-section {
  padding: 0;
  padding-bottom: 25px;
}

.dashboard-inner-wrapper {
  box-shadow: 0 5px 20px rgba(0, 128, 255, 0.14);
  background-color: #ffffff;
  width: 95%;
  padding: 30px 40px 80px;
}

.dashboard-inner-wrapper .profile-form h3 {
  font-size: 27px;
  font-family: "Avenir";
}

.back-btn {
  width: 107px;
  height: 50px;
  border: 1px solid #e6e6e6 !important;
  background-color: #ffffff;
  color: #686868;
  font-family: "Avenir LT Std";
  font-size: 16px;
  font-weight: 500;
  outline: none !important;
  transition: all 0.4s;
  cursor: pointer;
}
.back-btn:hover {
  border-color: #f97267 !important;
  transition: all 0.4s;
}

.back-btn img {
  margin-right: 20px;
}

.checkout-section form {
  justify-content: space-between;
}
.aero-left button {
  padding: 0;
  margin: 0;
  width: auto;
  height: auto;
  min-width: auto;
  line-height: 0;
  background-color: transparent !important;
  border: 0 !important;
  outline: none !important;
  box-shadow: unset !important;
  cursor: pointer;
}

.aero-left button:hover {
  background-color: transparent !important;
}

.aero-left {
  display: flex;
  align-items: center;
}

.aero-left h4 {
  font-family: 'fsme-regular';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  
/* identical to box height, or 117% */


/* color-06 */
  color: #003430;
  margin: 0;
}
/* end dashboard checkout css */

/* dashboard book detail css */

.dashborad-full-wrapper {
  width: 100%;
  padding: 40px 25px 100px;
}

.dashborad-full-wrapper .bundle-full-description {
  max-width: 642px;
}

.dashborad-full-wrapper .bundle-full-description p,
.dashborad-full-wrapper .bundle-full-description ul li {
  font-size: 14px;
}

/* end dashboard book detail css */

/* order page css */

.order-datatable {
  overflow: auto;
}

.order-datatable .table-head {
  color: #161616;
  font-family: "Avenir";
  font-size: 16px;
  font-weight: 800;
  letter-spacing: 0.27px;
  text-align: center;
  justify-content: center;
  height: 52px;
  background-color: #f9f9f9;
  border-right: 1px solid #e6e6e6;
  padding: 10px 15px !important;
}

.order-datatable .table-content {
  color: #606060;
  font-family: "Avenir LT Std";
  font-size: 16px;
  font-weight: 500;
  border-right: 1px solid #e6e6e6;
  padding: 10px 25px !important;
  justify-content: center;
}

.order-datatable mat-row {
  align-items: stretch;
}

.order-detail-table table td .orderBookTitle,
.order-detail-table table td.orderBookAuthor {
  word-break: break-all;
}

.order-datatable .table-book-field {
  min-width: 377px;
}

.order-datatable .table-book-field,
.order-datatable .table-content.table-author-field {
  word-break: break-all;
}

.order-datatable .table-content.table-book-field {
  justify-content: flex-start;
}

.order-datatable .table-action-field {
  max-width: 190px;
}

.order-datatable .table-book-field img {
  margin-right: 11px;
  width: 50px;
  margin-left: 5px;
}

.action-dropmenu .order-action-btn {
  height: 30px !important;
  line-height: 30px !important;
  padding: 0px 10px;
  color: #606060;
  font-family: "Avenir LT Std";
  font-size: 16px;
  font-weight: 500;
  outline: none !important;
}

.gold-color {
  color: gold;
}

.mat-menu-panel.action-dropmenu {
  width: 95px;
  min-width: 95px;
}

.table-action-field .mat-button {
  min-width: auto;
  background: transparent;
  outline: none !important;
  padding: 0;
  margin: 0;
  line-height: initial;
}

.table-payment-field span {
  width: 90px;
  height: 25px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Avenir LT Std";
  font-size: 11px;
  font-weight: 500;
}

.table-payment-field span.Pending,
.table-payment-field span.pending {
  background-color: #ffeff4;
  color: #f97267;
}

.table-payment-field span.Complete,
.table-payment-field span.complete {
  background-color: #e1f8ec;
  color: #00ae33;
}

.filter-section {
  /* box-shadow: 0 5px 20px rgba(0, 128, 255, 0.14);
  background-color: #ffffff; */
  margin-bottom: 20px;
}

.filter-section mat-card {
  padding: 0;
  display: flex;
  align-items: center;
}

.filter-section ul {
  display: flex;
  list-style: none;
  align-items: center;
  margin-bottom: 0;
  padding-left: 0;
  padding: 20px 30px;
  height: 100%;
  flex-wrap: wrap;
}

.filter-section ul li {
  display: flex;
  align-items: center;
  margin: 0 5px 5px;
}

.filter-title {
  margin-right: 25px !important;
  color: #142e3f;
  font-family: "Avenir Book";
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 0.4px;
}

.filter-title img {
  margin-right: 10px;
}

.filter-section .filter-button,
.filter-section .custom-select,
.filter-section .dateRange,
.datepicker-section.input-full-width .form-control {
  height: 45px;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  background-color: #ffffff;
  color: #a0a0a0;
  font-family: "Avenir Book";
  transition: all 0.4s;
  cursor: pointer;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  padding: 10px 25px;
}

.filter-section .filter-button:hover,
.filter-section .filter-button.active {
  border-radius: 4px;
  background-color: #161616;
  transition: all 0.4s;
  color: #fff;
}

.filter-section li.or-seperate {
  padding: 0 10px;
  color: #484848;
  font-family: "Avenir Book";
  font-size: 16px;
  font-weight: 800;
}

.order-datepicker {
  width: 130px;
  height: 45px;
  display: inline-flex;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  background-color: #ffffff;
  position: relative;
}

.order-datepicker .date-input {
  margin: 0;
  height: 45px;
  color: #a0a0a0;
  font-family: "Avenir LT Std";
  font-size: 15px;
  font-weight: 400;
  padding: 0 8px;
  width: 100%;
  border: 1px solid #e6e6e6;
  background-color: #ffffff;
}

.order-datepicker .mat-form-field-flex,
.order-datepicker .mat-form-field-infix {
  padding: 0 !important;
  border: 0px !important;
}

.order-datepicker .mat-datepicker-toggle {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 45px;
  cursor: pointer;
}
.table-search-box .cursor-pointer {
  cursor: pointer;
}
.cursor-pointer {
  cursor: pointer;
}

.avatar-image {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.table-search-box .search-input {
  width: 100% !important;
  height: 45px;
  border-radius: 4px;
  border: 1px solid #e6e6e6 !important;
  position: relative;
  padding: 0 15px !important;
  padding-right: 50px !important;
  color: #a0a0a0;
  font-family: "Avenir LT Std";
  font-size: 15px;
  font-weight: 400;
  outline: none !important;
}


.table-search-box {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.table-search-box .search-icon {
  position: absolute;
  right: 10px;
  opacity: 0.6;
  z-index: 9;
}

.table-search-field {
  padding: 0 30px;
  width: 100%;
}

.ordered-table-section {
  padding-bottom: 40px;
}

.mat-paginator-page-size-label,
.mat-paginator-page-size-value,
.mat-paginator-range-label {
  font-size: 14px;
  font-family: "Avenir LT Std";
  font-weight: 500;
  color: #161616;
}
.generated-email-table .mat-header-cell {
  color: #003430;
  font-family: 'fsme';
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}
.generated-email-table .mat-cell, .mat-footer-cell {
  color: #003430;
  font-family: 'fsme-regular';
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
}
.generated-email-table .table-search-box input {
  height: 57px;
  border-radius: 8px;
  border: 1px solid #d4d4d4;
  background-color: #ffffff;
  color: #999999;
  font-family: 'fsme-regular';
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}
/* end order page css */

/* sub category page */

.subcat-title .mat-card {
  padding: 0;
}

.subcat-title .subcat-title-left h3,
.teacher-title h3 {
  color: #161616;
  font-family: "Avenir";
  font-size: 30px;
  font-weight: 500;
  font-style: italic;
  margin-bottom: 15px;
}

.subcat-title .subcat-title-left p {
  color: #686868;
  font-family: "Avenir";
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  margin: 0;
}

.subcat-title {
  padding-bottom: 20px;
  margin-bottom: 25px;
  border-bottom: 1px solid #eeeeee;
}

.subcat-title-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.subcat-title-right .table-search-field {
  width: 54%;
}

.subcat-title-right label.mat-radio-label {
  margin: 0;
}

.subcat-title-right .mat-radio-button ~ .mat-radio-button {
  margin-left: 20px;
}

.subcat-title-right .mat-radio-button {
  color: #686868;
  font-family: "Avenir";
  font-size: 15px;
  font-weight: 500;
}

.subcat-title-right .mat-radio-label-content {
  padding-left: 15px;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #f97267;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f97267;
}

/* end sub category page */

/* book shop css */

.book-shop-section {
  padding: 30px 0;
}

.book-shop-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 50px 60px;
  box-shadow: 0 5px 20px rgba(0, 128, 255, 0.14);
  cursor: pointer;
}

/* .stage-light-blue-bg {
    background-color: #54d0ef;
}
.stage-red-bg {
    background-color: #f97267;
}
.stage-purle-bg {
    background-color: #8878ee;
}
.stage-yellow-bg {
    background-color: #ff914d;
}
.stage-green-bg {
    background-color: #7ed956;
} */

.stage-bg-0 {
  background-color: #54d0ef;
}

.stage-bg-1 {
  background-color: #f97267;
}

.stage-bg-2 {
  background-color: #8878ee;
}

.stage-bg-3 {
  background-color: #ff914d;
}

.stage-bg-4 {
  background-color: #7ed956;
}

.stage-bg-5 {
  background-color: #5e17eb;
}

.stage-bg-6 {
  background-color: #ff88b5;
}

.stage-bg-7 {
  background-color: #54d0ef;
}

.stage-bg-8 {
  background-color: #ffd675;
}

.stage-bg-9 {
  background-color: #d7ab97;
}

.stage-bg-10 {
  background-color: #00e1be;
}

.stage-bg-11 {
  background-color: #ffbf56;
}

.stage-bg-12 {
  background-color: #f97267;
}

.stage-bg-13 {
  background-color: #8878ee;
}

.stage-bg-14 {
  background-color: #fc97b4;
}

.stage-bg-15 {
  background-color: #7ed956;
}

.stage-bg-16 {
  background-color: #ff914d;
}

.stage-bg-17 {
  background-color: #54d0ef;
}

.book-shop-content h3 {
  color: #ffffff;
  font-family: "Avenir";
  font-size: 40px;
  font-weight: 800;
  text-transform: uppercase;
}

.book-shop-section mat-card {
  padding: 0;
  margin-bottom: 2.5%;
  margin-right: 2.5%;
}

.book-shop-section mat-card:nth-child(3n) {
  margin-right: 0;
}

.book-shop-content {
  width: 50%;
}

.book-shop-img {
  width: 50%;
  padding-left: 20px;
  text-align: right;
}

.book-shop-img img {
  max-width: 100%;
}

/* end book shop css */

/* key stage 1 css */

.key-stage-detail {
  box-shadow: 0 5px 20px rgba(0, 128, 255, 0.14);
  height: 247px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 35px 30px;
  cursor: pointer;
}

.English.key-stage-detail,
.english.key-stage-detail,
.Games.key-stage-detail,
.games.key-stage-detail {
  background-color: #54d0ef;
}

.key-stage-detail.match,
.Business.key-stage-detail,
.key-stage-detail.Match,
.business.key-stage-detail {
  background-color: #f97267;
}

.History.key-stage-detail,
.history.key-stage-detail {
  background-color: #5e17eb;
}

.Chemistry.key-stage-detail,
.chemistry.key-stage-detail {
  background-color: #ff914d;
}

.Story.key-stage-detail,
.story.key-stage-detail {
  background-color: #7ed956;
}

.Adventure.key-stage-detail,
.adventure.key-stage-detail,
.Geology.key-stage-detail,
.geology.key-stage-detail {
  background-color: #8878ee;
}

.Animal.key-stage-detail,
.animal.key-stage-detail {
  background-color: #ff88b5;
}

.fashion.key-stage-detail,
.Fashion.key-stage-detail {
  background-color: #ffd675;
}

.Flowers.key-stage-detail,
.flowers.key-stage-detail {
  background-color: #d7ab97;
}

.Science.key-stage-detail,
.science.key-stage-detail {
  background-color: #00e1be;
}

.Health.key-stage-detail,
.health.key-stage-detail {
  background-color: #ffbf56;
}

.dieting.key-stage-detail,
.Dieting.key-stage-detail {
  background-color: #fc97b4;
}

.key-stage-content h3 {
  color: #ffffff;
  font-family: "Avenir";
  font-size: 24px;
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
}

.key-stage-content {
  padding-top: 40px;
}

.book-shop-section.key-stage-section mat-card {
  margin-right: 1.25%;
  margin-bottom: 20px;
}

.book-shop-section.key-stage-section mat-card:nth-child(5n) {
  margin-right: 0;
}

.title-date {
  display: flex;
  align-items: flex-end;
}

.title-date .back-btn {
  margin-left: 60px;
}

.book-shop-img.key-stage-img {
  padding: 0;
}

/* end key stage 1 css */

/* profile page css */

.user-profile.student-profile h4 {
  color: #666666;
  font-family: "Avenir";
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  margin-bottom: 15px;
}

.user-profile.student-profile {
  padding: 40px 15px 30px;
}

/* end profile page css */

/* success order css */

.dash-success-order {
  box-shadow: 0 5px 20px rgb(0 128 255 / 14%);
  background-color: #ffffff;
  padding: 85px 0;
  text-align: center;
}

/* en success order css */

.update-btn {
  background: #f97267;
  color: #fff;
  font-size: 18px;
  padding: 7px 30px !important;
  font-family: "Avenir";
  font-weight: 500;
  height: 55px;
}

a.add-adress-redbtn {
  color: #f97267 !important;
  font-family: "Avenir LT Std";
  font-size: 20px;
  font-weight: 500;
  line-height: 21px;
  text-decoration: none;
}

.user-details-field h3 span {
  padding-left: 50px;
}

.address-heading {
  margin-bottom: 30px !important;
}

/********** Student Module Css ***********/

.loader-section.full-page-loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 99;
  background: #000000b5;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/********** cart page **************/

.cart-detail .cart-table {
  background-color: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}

.cart-detail .cart-table::-webkit-scrollbar {
  width: 2px;
}

/* Track */

.cart-detail .cart-table::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */

.cart-detail .cart-table::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */

.cart-detail .cart-table::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.cart-detail .cart-table tr:nth-child(1) {
  background-color: #f97267;
  height: 56px;
}

.cart-detail .cart-table tr th {
  font-size: 18px;
  color: #ffffff;
  padding: 12px 0px;
  text-align: center;
  border: 1px solid #ff8177;
  font-family: "Avenir";
  font-weight: 900;
}

.cart-detail .cart-table tr th:nth-child(4) {
  width: 130px;
}

.cart-table tr td {
  padding: 25px 35px;
  vertical-align: middle;
  text-align: center;
}

.cart-table .cart-product-detail {
  display: flex;
  align-items: center;
}

.cart-table .cart-product-detail .cart-content {
  padding-left: 20px;
}

.cart-table h4 {
  margin-bottom: 10px;
  color: #000000;
  font-family: "Avenir LT Std";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.15px;
  overflow-wrap: anywhere;
}

.cart-table .cart-product-price h4 {
  color: rgb(249 114 103);
}

.cart-table h4 span {
  color: #d4ab37;
  padding-left: 5px;
}

.cart-table p {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: rgb(119, 119, 119);
  line-height: 1.714;
  margin-bottom: 10px;
}

.cart-table .cart-product-detail .cart-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.cart-table .cart-product-detail .cart-content li {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: rgb(119, 119, 119);
  line-height: 1.3em;
  position: relative;
  padding-left: 15px;
  margin-bottom: 7px;
  text-align: left;
}

.cart-table .cart-product-detail .cart-content li span {
  padding-left: 5px;
}

.cart-table .cart-product-detail .cart-content li:last-child {
  margin-bottom: 0;
}

.cart-table .cart-product-price span {
  padding-left: 5px;
  color: rgb(33, 33, 33);
}

.cart-table .remove-icon .fa {
  font-size: 25px;
  color: #b7b7b7;
}

.cartproduct-quantity {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cartproduct-quantity input.qty {
  width: 55px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(223, 223, 223);
  background-color: rgb(248, 247, 247);
  height: 40px;
  border-left: 0;
  border-right: 0;
  border-radius: 0px;
  font-size: 22px;
  font-family: "Roboto", sans-serif;
  color: rgb(32, 32, 32);
  text-align: center;
  outline: none !important;
}

.cartproduct-quantity .button-container .btn {
  height: 40px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(223, 223, 223);
  border-radius: 0px;
  background-color: rgb(248, 247, 247);
  font-size: 20px;
  color: rgb(32, 32, 32);
  text-align: center;
  outline: none;
  box-shadow: none;
  line-height: 20px;
  font-family: "Avenir";
}

.place-order-section {
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.09);
  padding: 60px 30px;
}

.place-order-section p {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: rgb(25, 25, 25);
  line-height: 1.571;
  margin-bottom: 20px;
}

.place-order-section ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
  padding: 20px 0;
  border-top: 2px dashed #eeeeee;
  border-bottom: 2px dashed #eee;
  margin-bottom: 20px;
}

.place-order-section li.total-title {
  font-size: 18px;
  color: rgb(0, 0, 0);
  text-transform: uppercase;
  line-height: 1;
  font-weight: 600;
  font-family: "Avenir";
}

.place-order-section li.total-price {
  font-size: 23px;
  font-family: "Avenir";
  color: rgb(249 114 103);
  line-height: 1;
  padding-left: 10px;
}

.place-order-section li.total-price span {
  font-size: 27px;
  color: rgb(249 114 103);
  line-height: 0.6;
  padding-left: 2px;
  font-family: "Avenir";
  font-weight: 600;
}

.place-order-section .btn-group {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.place-order-section .btn {
  border-radius: 4px;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  line-height: 0.9;
  margin-bottom: 10px;
  transition: all 0.5s;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  font-family: "Avenir";
}

.place-order-section .order-place-btn {
  background-color: rgb(249 114 103);
}

.place-order-section .shopping-btn {
  background-color: rgb(84 208 239);
  margin-bottom: 0;
}

/********** End cart page **************/

/**** Error Page ****/

.error-section {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffeeec;
  background: url(./assets/img/error-banner.png) no-repeat;
  width: 100%;
  height: 100vh;
  padding: 0 !important;
  padding: 0 !important;
  background-size: cover;
  background-position: center;
  min-height: 1000px;
}

.error-section .error-field {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 100%;
  font-family: "Avenir";
  text-align: center;
  flex-direction: column;
}

.page-back-btn {
  font-weight: 300;
  color: #ffffff;
  font-size: 23px;
  transition: all 0.3s linear;
  background: #f97267;
  font-family: "Avenir";
  min-width: 280px !important;
  height: 60px;
  outline: none !important;
  text-transform: uppercase;
  border: 1px solid white !important;
  padding: 10px 30px !important;
}

.error-field p {
  font-size: 50px;
  text-align: center;
  font-weight: 400;
  font-family: "Avenir LT Std";
  color: #262626;
  margin-bottom: 30px;
}

.error-field {
  position: relative;
  height: 100%;
  width: 100%;
}

.error-section .inner-wrapper {
  height: 100%;
  width: 100%;
  max-width: 100%;
}

.error-row {
  height: 100%;
}

.error-logo {
  position: absolute;
  top: 85px;
}

.error-img {
  position: absolute;
}

.error-content {
  position: absolute;
  bottom: 85px;
}

/**** End Error Page ****/

/*New Page Pupils and Teacher Css */

.pupils-stats {
  padding-bottom: 40px;
}

.pupils-stats mat-card {
  box-shadow: 0 6px 22px rgb(63 103 152 / 10%) !important;
  border-radius: 10px !important;
  background-color: #ffffff !important;
  padding: 20px 25px;
  min-height: 150px;
}

.pupils-stats-card {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  height: 100%;
}

.pupils-stats-card .pupils-img {
  width: 30%;
}

.pupils-stats-card .pupils-img img {
  max-width: 100%;
}

.pupils-stats-card .pupils-content {
  text-align: right;
  width: 70%;
  padding-left: 15px;
}

.pupils-stats-card .pupils-content h3 {
  color: #003430;
  font-family: 'fsme';
  font-weight: 700;
  line-height: 40.5px;
  margin-bottom: 0px;
  font-size: 32px;
  padding-right: 0;
}
.pupils-stats-card .pupils-content p {
  color: #003430;
  font-family: 'fsme-regular';
  font-weight: 500;
  line-height: 24.3px;
  margin-bottom: 0;
  font-size: 16px;
  height: 60px;
}

.pupils-stats-card .pupils-content h3 span {
  margin-left: 5px;
}

.checkbox-table .table {
  background: transparent;
  border-collapse: initial;
  border-spacing: 0 10px !important;
}

.checkbox-table .table tbody tr {
  box-shadow: 0 6px 22px rgb(249 249 250);
  border-radius: 2px;
}

.checkbox-table .table tbody tr td:first-child {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-left: 1px solid #eeeeee;
}

.checkbox-table .table tr td:last-child {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-right: 1px solid #eeeeee;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: transparent;
}

.mat-checkbox-frame {
  border-color: #e7e7e7;
}

.mat-checkbox-inner-container {
  width: 24px !important;
  height: 24px !important;
  border-radius: 3px;
  border: 1px solid #f97267;
  background-color: #ffffff;
}

.mat-checkbox-checkmark-path {
  stroke: #010101 !important;
}

.mat-checkbox-mixedmark {
  background-color: #010101;
}

.mat-checkbox-checked .mat-checkbox-mixedmark,
.mat-checkbox-indeterminate .mat-checkbox-mixedmark {
  transform: unset !important;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #4e5f74;
}

section.table-section h4 {
  color: #161616;
  font-family: "fsme-regular";
  font-weight: 600;
  line-height: 21.6px;
  font-size: 26px;
  margin-bottom: 15px;
}

/* .table-noHead thead {
  display: none;
} */

.table tr td {
  padding: 10px 10px;
  vertical-align: middle;
  border: 0px;
}

.checkbox-table .table tr td,
.checkbox-table .table tr th {
  padding: 10px 20px;
  vertical-align: middle;
  border: 0px;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  background: #fff;
}

.table-selectbox {
  width: 80px;
}

.table-selectbox label.mat-checkbox-layout {
  margin-bottom: 0;
}

.checkbox-table .table thead tr {
  height: auto;
}

.checkbox-table .table thead th {
  font-size: 16px;
  line-height: 21.6px;
  font-family: 'fsme';
  border-bottom: 0;
  vertical-align: middle;
  padding-top: 10px;
  padding-bottom: 5px;
  border: 0px;
  background-color: transparent;
  color: #003430;
  white-space: nowrap;
}

.checkbox-table tr td {
  font-family: 'fsme-regular';
  font-weight: 400;
  line-height: 21.6px;
  font-size: 17px;
  color: #003430;
}

.table-shortname {
  display: flex;
  align-items: center;
}

.class-shortname {
  width: 40px;
  height: 40px;
  background-color: #69aaff;
  color: #ffffff;
  text-transform: capitalize;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.expactation-btn-Below {
  border-radius: 35px;
  border: 1px solid #C382AF;
  background: #C382AF;
  margin: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
  font-size: 16px;
  font-weight: 400;
  font-family: 'fsme-regular';
  padding: 8px 16px;
  width: 162px;
  line-height: 0;
  height: 35px;
}

.expactation-btn-Risk {
  border-radius: 35px;
  border: 1px solid #F0A5A5;
  background: #F0A5A5;
  margin: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
  font-size: 16px;
  font-weight: 400;
  font-family: 'fsme-regular';
  padding: 8px 16px;
  width: 162px;
  line-height: 0;
  height: 35px;
}

.expactation-btn-At {
  border-radius: 35px;
  border: 1px solid #F0A5A5;
  background: #F0A5A5;
  margin: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
  font-size: 16px;
  font-weight: 400;
  font-family: 'fsme-regular';
  padding: 8px 16px;
  width: 162px;
}
.expactation-btn-Above {
  margin: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
  font-size: 16px;
  font-weight: 500;
  font-family: 'fsme-regular';
  background: #6478A5;
  width: 162px;
  border-color: #6478A5;
  border-radius: 35px;
  line-height: 0px;
  height: 35px;
}
.expactation-btn-Expected {
  border-radius: 35px;
  border: 1px solid #5AAF96;
  background: #5AAF96;
  margin: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
  font-size: 16px;
  font-weight: 400;
  font-family: 'fsme-regular';
  width: 162px;
  line-height: 0;
  height: 35px;
}

.pagination-section .mat-paginator-icon {
  width: 24px;
}

.pagination-section .mat-paginator {
  background: transparent;
}

.pagination-section .mat-icon-button {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  line-height: 32px;
  margin: 0 1px;
}

.table tr:nth-child(n) td .class-shortname {
  background: #5aaf96;
}

.table tr:nth-child(2n) td .class-shortname {
  background: #C382AF;
}

.table tr:nth-child(3n) td .class-shortname {
  background: #6478A5;
}

.table tr:nth-child(4n) td .class-shortname {
  background: #5aaf96;
}

.table tr:nth-child(5n) td .class-shortname {
  background: #C382AF;
}
.table tr:nth-child(6n) td .class-shortname {
  background: #6478A5;
}

.employee-img-name {
  display: flex;
  align-items: center;
}

.employee-img-name p {
  margin-bottom: 0;
  text-transform: capitalize;
}

.employee-img-name img {
  margin-right: 15px;
}

.example-sidenav {
  user-select: none;
}

.full-width {
  width: 100%;
}

.menu-expand-button {
  transition: 100ms ease-in-out;
  transform: rotate(0deg);
}

.menu-expand-button.rotated {
  transform: rotate(180deg);
}

.submenu-dropdown {
  display: none;
  padding: 0 40px;
}

.submenu-dropdown.expanded {
  display: block;
}

.sidenav-area .submenu-dropdown li a.dropmenu-list,
.sidenav-area .submenu-dropdown li.active a.dropmenu-list,
.sidenav-area .submenu-dropdown li:hover a.dropmenu-list {
  background: transparent !important;
  text-align: center !important;
}

.sidenav-area .submenu-dropdown li.active a.dropmenu-list {
  color: #f97267;
}

.sidenav-area .submenu-dropdown li {
  margin-bottom: 0;
}

.sidenav-area .submenu-dropdown li a.dropmenu-list:hover span,
.sidenav-area .submenu-dropdown li.active a.dropmenu-list span {
  width: 32px;
  display: block;
  height: 1px;
  background: #f97267;
  position: relative;
  top: 0;
  bottom: 0;
  margin: auto 0;
  margin-left: 15px;
}

.sidenav-area .submenu-dropdown li a.dropmenu-list {
  position: relative;
}

.page-header-left {
  display: flex;
  align-items: center;
}

.page-header-left button {
  margin-left: 40px;
}

.page-header-right .table-search-field {
  padding: 0;
}

.page-header-right .table-search-field .search-input {
  min-width: 220px;
}



.dialog-section {
  width: 534px;
  box-shadow: 0 6px 22px rgba(63, 103, 152, 0.1);
  border-radius: 15px;
  background-color: #ffffff;
  padding: 25px 32px;
}

.dialog-section-wrapper h4 {
  font-size: 36px;
  font-family: 'fsme';
  font-weight: 500;
  line-height: 32.4px;
  text-align: center;
  color: #003430;
}

.dialog-header {
  padding-bottom: 18px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 21px;
}

.mat-dialog-container {
  padding: 0 !important;
}

.dialog-section mat-card {
  padding: 0;
}

.dialog-section-wrapper .form-group {
  margin-bottom: 10px;
}

.dialog-section-wrapper .form-group label {
  font-size: 18px;
  font-weight: 500;
  line-height: 21.6px;
  text-align: left;
  font-family: 'fsme-regular';
  color: #003430;
  top: 23px;
}
.edit-reding-form .mat-form-field-wrapper {
  padding: 0px;
}

.edit-reding-form .mat-form-field-infix {
  border: 0;
  padding: 0;
}
.edit-reding-form input {
  height: 60px;
  background: #FAFAFA;
  border: 1px solid #DEDEDE;
  border-radius: 5px;
  font-family: 'fsme';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  color: #003430 !important;
  width: 95%;
  padding-left: 20px;
  margin-top: 0;
}
.edit-reding-form .mat-error {
  margin-top: 14px;
}
.edit-reding-form .form-group mat-label {
  margin-bottom: 10px;
  display: block;
  font-family: 'fsme-regular';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #003430;
}
.edit-reding-form textarea {
  height: 200px;
  background: #FAFAFA;
  border: 1px solid #DEDEDE;
  border-radius: 5px;
  font-family: 'fsme';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  color: #003430;
  width: 95%;
}
.edit-reding-form textarea.mat-input-element {
  padding-top: 20px;
  padding-left: 20px;
}
.dialog-section.edit-reding-form {
  width: 700px;
  background: #FFFFFF;
  box-shadow: 0px 6px 22px rgb(63 103 152 / 10%);
  border-radius: 12px;
  padding-top: 37px;
}

.edit-reding-form .mat-form-field-underline {
  height: 0;
  display: none;
}
.edit-reding-form .mat-form-field-appearance-legacy .mat-form-field-underline {
  height: 0;
  display: none;
}

.dialog-section-wrapper .form-group .form-control {
  height: 47px;
  border: 0px;
  border-radius: 4px;
  background-color: #f8fafb;
  color: #4e5f74;
  font-size: 14px;
  font-weight: 400;
  outline: none;
  box-shadow: none;
}

.form-button-group {
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-button-group .form-btn {
  width: 50%;
  box-shadow: none;
  font-family: 'fsme-regular';
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 18.9px;
  font-size: 18px;
  height: 55px;
  border-radius: 4px;
  margin-right: 10px;
}

.form-button-group .form-btn.cancel-btn {
  border: 1px solid #efefef;
  background-color: #ffffff;
  color: #b5b5b5;
}

.form-button-group .form-btn.submit-btn {
  background-color: #5AAF96;
  border: 1px solid #5AAF96;
  color: #fff;
}

.form-button-group .form-btn:last-child {
  margin-right: 0;
}

.inline-form-group {
  display: flex;
  align-items: center;
}

.inline-form-group .form-control {
  max-width: 371px;
  height: 48px;
  border-radius: 5px;
  border: 1px solid #e9e9e9;
  background-color: #ffffff;
  color: #424242;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 18.9px;
}

.inline-form-group .theme-btn {
  height: 48px;
  margin-left: 10px;
}

.add-teacher-form h5 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 21.6px;
  text-transform: uppercase;
  margin-bottom: 20px;
  color: #161616;
}

.add-teacher-section mat-card,
section.table-section mat-card {
  padding: 0 !important;
}

.add-teacher-section {
  margin-bottom: 40px;
}

section.teacher-table-section h4 {
  margin-bottom: 20px;
}

.pupils-table h3 {
  font-family: 'fsme';
  font-weight: 700;
  line-height: 27px;
  font-size: 26px;
  color: #161616;
}

.pupils-table-row {
  margin-bottom: 15px;
}

.custom-selectbox {
  font-family: 'fsme-regular';
  outline: none;
  cursor: pointer;
  color: #003430;
  border: 0;
  font-size: 17px;
}

.pupils-detail-card {
  margin-bottom: 20px !important;
  display: flex;
  align-items: flex-start;
}


.pupils-name-list {
  width: 24%;
  text-align: center;
  background: #FFFFFF;
  box-shadow: 0px 6px 22px rgb(63 103 152 / 10%);
  border-radius: 5px;
  padding-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.pupils-info-details {
  margin-left: 30px;
  width: 60%;
}
.jas-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  max-width: 258px;
  margin: 0 auto;
  margin-top: 78px;
}
ul.jas-list.list2 h4 {
  font-size: 14px;
  line-height: normal;
  margin: 0;
}

.jas-list li {
  display: flex;
  align-items: center;
}

.jas-list li h4 {
  margin: 0;
  padding-left: 12px;
  font-family: 'fsme-regular';
  font-style: normal;
  font-weight: 400;
  font-size: 11.7518px;
  line-height: 17px;
}

.jas-list li h4 span {
  padding-left: 12px;
  font-family: 'fsme';
}

.pupils-info-details ul {
  list-style: none;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  justify-content: space-between;
}

.pupils-info-details ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;
  box-shadow: 0px 6px 22px rgb(63 103 152 / 10%);
  border-radius: 5px;
  padding: 25px 20px;
  margin-bottom: 7px;
  width: 47%;
}

.pupils-info-details ul li:last-child {
  border-right: 0;
}

.pupils-info-content {
  color: #003430;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  font-family: 'fsme';
  text-align: center;
}

.pupils-info-icon {
  color: #003430;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.6px;
  text-align: left;
  font-family: 'fsme-regular';
  display: flex;
  align-items: center;
}

.pupils-info-icon img {
  margin-right: 10px;
}

.pupils-name-list .pupils-img img {
  width: 104px;
  height: 104px;
  border-radius: 50%;
}

/* .pupils-detail-content {
  margin-left: 25px;
} */

.pupils-detail-content h3 {
  font-size: 32px;
  line-height: 40.6px;
  text-align: center;
  font-family: fsme;
  color: #003430;
  padding-top: 15px;
}

.pupils-detail-content p {
  font-size: 16px;
  font-weight: 500;
  line-height: 24.3px;
  text-align: center;
  font-family: fsme-regular;
  color: #003430;
}

.pupils-detail-content p span {
  display: inline-block;
  width: 30px;
  text-align: center;
}

.pupils-chart {
  box-shadow: 0 6px 22px rgba(63, 103, 152, 0.1);
  border-radius: 5px;
  background-color: #ffffff;
  padding: 15px 25px;
}

.pupils-details-section mat-card {
  padding: 0;
}

/* .pupils-info-details ul li.pupils-age {
  width: 20%;
} */

/* .pupils-info-details ul li.pupils-changes {
  width: 25%;
} */

/* .pupils-info-details ul li.pupils-book-read {
  width: 25%;
} */

/* .pupils-info-details ul li.pupils-reading-age {
  width: 30%;
} */

/* end New Page Css */

/* new added */

.mat-button-disabled {
  background: #69998b !important;
  cursor: not-allowed !important;
  color: #efefef !important;
}

.mat-error {
  font-size: 14px;
  font-weight: 500;
  font-family: "Avenir LT Std";
  color: #f97267;
  display: block;
}

.login-body .form-group .input-group .eye-icon.mat-icon-button {
  width: auto !important;
  height: auto !important;
  outline: none !important;
}

.login-body
  .form-group
  .input-group
  .eye-icon.mat-icon-button
  .mat-button-ripple-round {
  position: unset;
}

.cancel-btn {
  width: 100%;
  height: 55px;
  box-shadow: 3px 4px 5px rgb(226 226 226);
  background-color: #f3f3f3;
  color: #2b2b2b;
  font-family: "Avenir";
  font-size: 17px;
  font-weight: 800;
  font-style: normal;
  letter-spacing: 1px;
  text-transform: capitalize;
  outline: none !important;
}

.custom-pagination-btn .mat-button-base {
  width: 35px;
  height: 35px;
  line-height: 18px;
  margin: 0 1px;
}

.address-field {
  margin-bottom: 15px;
}

.flex-btn-group {
  display: flex;
  align-items: center;
}

.sm-txt {
  text-transform: capitalize;
}

.flex-btn-group .cancel-btn {
  margin-left: 10px;
  letter-spacing: 0;
}

.reset-password-body .reset-btn {
  margin-top: 15px;
}

.login-body.reset-password-body {
  box-shadow: none;
}

.student-profile-section {
  padding: 0 !important;
}

span.buy-book-price {
  font-size: 20px;
}

.quantity-btn.school-quantity-btn {
  width: 130px;
  display: flex;
  justify-content: space-between;
  margin: auto;
  height: 43px;
}

.quantity-btn.school-quantity-btn .button-container .btn {
  height: 38px;
}

.quantity-btn.school-quantity-btn input.qty {
  height: 38px;
}

.cart-detail .cart-table tr th:nth-child(3),
.cart-detail .cart-table tr th:nth-child(4) {
  width: 145px;
}

.cart-table tr td {
  padding: 25px 20px;
}

.cart-detail .cart-table tr th:nth-child(5) {
  width: 120px;
}

.buy-book-content a h3 {
  transition: all 0.3s;
}

.buy-book-content a:hover h3 {
  color: #f97267;
  transition: all 0.3s;
}

footer h3 {
  font-size: 20px;
  font-family: "Avenir";
  color: #f97267;
  margin: 15px 0 4px;
  font-weight: 900;
}

.date-range-field .mat-form-field-wrapper {
  margin: 0 !important;
  padding: 0;
}

.date-range-value mat-datepicker-toggle button {
  width: 100%;
  height: 100%;
}

.date-range-value mat-datepicker-toggle button .mat-button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.date-range-value .mat-date-range-input-inner {
  font-size: 16px;
  font-family: "Avenir Book";
  font-weight: 400;
  color: #676767;
}

.date-range-field .mat-form-field-flex {
  padding: 0 !important;
  margin: 0 !important;
  height: 45px;
}

.date-range-field.mat-form-field-appearance-outline:not(.mat-form-field-disabled)
  .mat-form-field-flex:hover
  .mat-form-field-outline-thick {
  opacity: 0.3 !important;
}

.mat-form-field-appearance-outline
  .mat-form-field-outline-thick
  .mat-form-field-outline-start,
.mat-form-field-appearance-outline
  .mat-form-field-outline-thick
  .mat-form-field-outline-end,
.mat-form-field-appearance-outline
  .mat-form-field-outline-thick
  .mat-form-field-outline-gap {
  border-width: 1px !important;
}

.date-range-field .mat-form-field-infix {
  padding: 0 10px !important;
  display: flex;
  align-items: center;
  border-top: 0 solid transparent;
  height: 45px;
}

.date-range-value {
  width: 100%;
  padding-right: 20px;
}

.date-range-field .mat-form-field-outline {
  height: 45px;
  top: 0 !important;
}

.date-range-icon button {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  padding: 6px 5px;
  background: transparent !important;
  border-radius: 0 !important;
}

.date-range-field {
  width: 240px;
}

.date-range-icon button:focus {
  outline: none !important;
  background: transparent !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}

.date-range-field span.mat-date-range-input-mirror {
  min-width: 85px;
}

.mat-date-range-input-separator {
  margin-right: 10px;
}

.subcat-book-section .buy-book-content h3 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 42px;
}

.date-range-value .mat-date-range-input-inner::-webkit-input-placeholder {
  /* Edge */
  color: #a0a0a0;
}

.date-range-value .mat-date-range-input-inner:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a0a0a0;
}

.date-range-value .mat-date-range-input-inner::placeholder {
  color: #a0a0a0;
}

.orderList-field {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #f2f2f2;
}

.orderList-field:first-child {
  margin-top: 0;
  padding-top: 0px;
  border-top: 0px solid #f2f2f2;
}

.mat-tooltip {
  font-size: 14px !important;
  background: #fff !important;
  font-family: "Avenir Book" !important;
  font-weight: 400 !important;
  color: #000 !important;
  box-shadow: 0px 6px 22px rgb(63 103 152 / 10%) !important;
  font-family: 'fsme-regular' !important;
}


.order-detail-field {
  margin-right: 25px;
}

.order-billing-details {
  max-width: 100%;
  background: #ffffff;
  padding: 30px 24px;
  box-shadow: 0 5px 20px rgb(0 128 255 / 14%);
}

.order-detail,
.order-detail-pricing {
  background: #ffffff;
  padding: 20px 15px;
  box-shadow: 0 5px 20px rgb(0 128 255 / 14%);
}

.order-detail-pricing {
  width: 400px;
  max-width: 100%;
  margin-left: auto;
  margin-top: 30px;
}

.order-details-wrapper {
  width: 100%;
}

.order-details-wrapper mat-card {
  padding: 0 !important;
}

.order-page-title {
  padding-bottom: 35px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

h3.order-detail-id {
  font-size: 22px;
  font-weight: 500;
  font-family: "Avenir";
  margin-bottom: 0px !important;
  color: #f97267;
  line-height: 20px;
}

.order-detail-section h3 {
  font-size: 20px;
  font-weight: 600;
  font-family: "Avenir";
  margin-bottom: 10px;
}

.order-billing-details .address-field {
  margin-bottom: 30px;
}

.order-billing-details p {
  font-size: 16px;
  font-weight: 400;
  font-family: "Avenir";
  margin-bottom: 8px;
  line-height: 1.5;
  color: #666666;
}

.order-billing-details p b {
  font-weight: 600;
  font-family: "Avenir Book";
}

.order-detail p {
  color: #666666;
  font-family: "Avenir";
  font-size: 16px;
  font-weight: 500;
}

.order-detail-pricing .checkout-pricing p {
  font-size: 16px;
}

.order-detail-pricing h3 {
  margin-bottom: 0 !important;
}

.order-detail-table table th {
  color: #666666;
  font-family: "Avenir";
  font-size: 17px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  padding: 15px 15px;
}

.order-detail-table table td {
  color: #666666;
  font-family: "Avenir";
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  padding: 20px 15px;
}

.order-detail-table table thead {
  height: 55px;
  background-color: #fafafa;
}

.order-detail-table table {
  width: 100%;
}

.order-detail p b {
  font-weight: 600;
  font-family: "Avenir Book";
  letter-spacing: 0.6px;
}

p.no-order-notes {
  color: #c7c7c7;
}

.order-detail-field {
  padding-bottom: 40px;
}

.mat-tooltip-trigger {
  width: 35px;
  height: 35px;
  flex-shrink: 0;
  /* line-height: 35px; */
  margin: 0 1px;
}

.row.membership-tab-section {
  padding-bottom: 20px;
}

.row.membership-tab-section mat-button-toggle-group {
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.row.membership-tab-section .mat-button-toggle {
  font-size: 18px;
  width: 130px;
  height: 50px;
  margin: 0 5px;
  border-radius: 4px;
  border: 0px;
  background: #dee2e6;
  color: #000;
  font-family: "Avenir";
  font-weight: 500;
  outline: none !important;
}

.row.membership-tab-section .mat-button-toggle.mat-button-toggle-checked {
  background: #f97267;
  color: #fff;
}

.row.membership-tab-section .mat-button-toggle button {
  outline: none !important;
  border: 0px !important;
  box-shadow: none !important;
}

.row.membership-tab-section
  .mat-button-toggle-appearance-standard:not(.mat-button-toggle-disabled):hover
  .mat-button-toggle-focus-overlay {
  opacity: 0;
}

.bundle-full-description h4 del {
  color: #b8b8b8;
  font-family: "Avenir";
  font-size: 19px;
  font-weight: 500;
  text-decoration: line-through;
  margin-right: 7px;
}

.bundle-full-description h4 .final-price {
  color: #f97267;
  font-family: "Avenir";
  font-size: 24px;
  font-weight: 800;
  margin-right: 7px;
}

.bundle-full-description h4 .discount {
  color: #f97267;
  font-family: "Avenir";
  font-size: 19px;
  font-weight: 500;
}

.date-range-value .mat-date-range-input-end-wrapper {
  text-align: right;
  padding-right: 15px;
}

.cart-table .cart-product-detail .cart-img img,
.cart-img img {
  max-width: 85px;
}

.bold-font,
.membership-list mat-card-content ul li.bold-font {
  font-weight: 900;
  font-size: 21px;
}

.bundle-name-logo img {
  max-width: 100%;
}

.bundle-img-section {
  position: relative;
}

.bundle-name-logo {
  position: absolute;
  top: -40px;
  right: -30px;
  width: 100px;
}

.bundle-img-section .bundle-img img {
  max-width: 100%;
  max-height: 235px;
}

.bundle-img-section .bundle-img {
  margin-top: 25%;
}

.bundle-big-img .bundle-name-logo {
  top: 20px;
  right: 20px;
}

.orderList-field {
  display: flex;
  align-items: center;
  width: 100%;
}

td.cart-img.order-details-title-img {
  display: flex;
  align-items: center;
}

td.cart-img.order-details-title-img img {
  padding-right: 10px;
}

.membership-cancel-btn {
  background-color: #f97267;
  width: 100%;
  height: 55px;
  color: #ffffff;
  font-family: "Avenir";
  font-size: 17px;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: all 0.3s;
}

.membership-cancel {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -21px;
}

.membership-cancel button {
  height: auto;
  width: 70%;
  padding: 8px 19px;
  line-height: initial;
  font-size: 15px;
}

.teacher-title {
  display: block;
  width: 100%;
  margin-bottom: 20px;
}

.teachers-love {
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 30px;
  padding-bottom: 10px;
}

.mat-grey-body h4 {
  color: #f97267;
  font-size: 16px;
  font-family: "Avenir";
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 7px;
}

.teachers-love .buy-book-btn {
  background: #f97267;
}

.teachers-love .buy-book-btn:hover {
  background: #54d0ef;
}

.school-book-slider {
  padding-top: 30px;
  padding-bottom: 60px;
}

.school-book-slider .owl-dots.ng-star-inserted {
  bottom: -40px;
}

.table-action-field {
  width: 160px;
  text-align: center !important;
}

.pupils-chart img {
  max-width: 100%;
}

select.form-control {
  height: 45px !important;
}

.autocomplete-school-text {
  display: flex;
  flex-direction: column;
  line-height: normal;
  align-items: flex-start;
  justify-content: center;
}

.add-input-button {
  background: #dddddd;
  color: #020202;
  font-size: 16px;
  outline: none !important;
  border: 0px;
}

.pupils-info-icon img {
  width: 40px;
}

.pupils-detail-img img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

/* .pupils-info-content.book-title {
  font-size: 18px;
  line-height: 23px;
} */

.pupils-info-content.book-title {
  -webkit-line-clamp: 1 !important;
}
/* end new added */

/* end new added */

/****** new design-css ******/

.login-signup-wrapper {
  background: url(assets/img/teacher-login.png) no-repeat;
  min-height: 100vh;
  background-size: 96.8% 100%;
  position: relative;
}




.login-signup-wrapper:before {
  background: url(assets/img/login-line.png) no-repeat;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 47%;
  height: 172px;
  background-size: cover;
}

/* .login-signup-wrapper .mat-left-card {
  background: #fafafa !important;
} */

.login-left-section-techer {
  background: url(assets/img/login-bg.png) no-repeat;
  background-size: contain;
  margin: 0 auto;
  padding: 100px 84px 136px 61px;
  max-width: 654px;
}
.login-signup-wrapper .mat-right-card {
  background: #fff !important;
}


.login-left-sections-school p {
  color: #023d38;
  font-family: 'fsme-regular';
  font-size: 30px;
  font-weight: 400;
  text-align: center;
  margin: 0;
}

.login-left-sections-school .login-left-img {
  margin: 0;
}

.login-left-sections-school {
  background: url(assets/img/book-bg.png) no-repeat;
  background-size: contain;
  margin: 0 auto;
  padding: 122px;
  max-width: 775px;
  background-position: center;
}

.login-left-img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 26px;
}
.login-left-section-techer h2 {
  color: #023d38;
  font-family: 'fsme';
  font-size: 35px;
  font-weight: 700;
  margin: 0;
  margin-left: 12px;
}

.login-left-section-techer p {
  color: #023d38;
  font-family: 'fsme-regular';
  font-size: 30px;
  font-weight: 400;
  margin: 0;
  text-align: center;
}

.login-left-img img {
  max-width: 100%;
}

.login-right-section .right-menu p {
  margin: 0;
  color: #666666;
  font-family: "Avenir LT Std";
  font-size: 17px;
  font-weight: 500;
  line-height: 21px;
  margin-right: 35px;
}

.login-right-section {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 30px;
}

.new-login-btn {
  width: 137px;
  height: 55px;
  box-shadow: 3px 4px 5px rgb(249 114 103 / 32%);
  background-color: #f97267;
  outline: none !important;
  color: #ffffff;
  font-family: "Avenir Book";
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.login-right-section .login-body {
  width: 751px;
  box-shadow: none;
  border-radius: 0px;
  background-color: #ffffff;
  padding: 0;
  text-align: left;
}
.login-right-section .login-body form.example-form {
  padding: 0 40px;
}


.login-right-section .login-body h2 {
  font-family: clever bold;
  line-height: 55px;
  color: #023d38;
  font-size: 48px;
  background: url(assets/img/techer-line-bg.png) no-repeat;
  background-size: cover;
  height: 165px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 13px;
}
.login-right-section .login-body .school-dark-bg {
  background-color: #e6ccde;
}

.login-right-section .login-body .techar-bg-dark {
  background-color: #f8dada;
}

.login-right-section .login-body p {
  margin-bottom: 45px;
}

.login-body .form-group .input-group .eye-icon.mat-icon-button mat-icon {
  color: #999999;
}

section.new-login-section {
  display: flex;
  align-items: center;
  min-height: calc(100vh - 139px);
}

.login-section-row {
  height: 100%;
  width: 100%;
  padding: 40px 0;
  align-items: center;
}

.forget-btn {
  padding: 0 !important;
  line-height: initial;
  color: #101010;
  font-family: "Avenir Book";
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
  outline: none !important;
}

.login-right-section .login-body .form-control {
  box-shadow: none !important;
}

.login-right-section .login-body .form-group {
  margin-bottom: 25px;
}

.signup-right-section .login-body .form-group {
  margin-bottom: 15px;
}

.terms-link {
  background: transparent;
  border: 0px;
  line-height: normal !important;
  padding: 0 !important;
  color: #ff6760;
  font-family: "Avenir Book";
  font-size: 14px;
  font-weight: 600;
  outline: none !important;
}

.signup-right-section .login-body .form-group.button-group {
  margin-bottom: 20px;
}

.login-body.reset-password-body h2 {
  font-size: 35px;
  line-height: 60px;
  margin-bottom: 25px;
}

.reset-password-section mat-card {
  padding: 0 !important;
}

.reset-password-section .reset-password-body {
  width: 554px;
  box-shadow: 0 5px 20px rgba(0, 128, 255, 0.14);
  border-radius: 10px;
  background-color: #ffffff;
  padding: 35px 35px;
}

.select-charity-section {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.select-charity-section mat-radio-button {
  border: 1px solid #eaeaea;
  background-color: #ffffff;
  padding: 25px 15px;
  margin-bottom: 15px;
  height: 200px;
  position: relative;
}

.select-charity-list {
  display: flex;
  align-items: flex-start;
}

.charity-list-content {
  display: block;
  margin-left: 14px;
}

.select-charity-section mat-radio-button label.mat-radio-label {
  white-space: normal;
  margin: 0 !important;
  align-items: flex-start;
}

.select-charity-section .mat-radio-label .mat-radio-container {
  margin-top: 20px;
  margin-right: 10px;
  width: 28px;
  height: 28px;
}

.select-charity-section
  .mat-radio-button.mat-accent.mat-radio-checked
  .mat-radio-outer-circle {
  background: #f97267;
}

.select-charity-section .mat-radio-outer-circle {
  width: 28px;
  height: 28px;
  border-radius: 0;
  border: 1px solid #eaeaea;
  background-color: #ffffff;
}

.select-charity-section
  .mat-radio-button.mat-accent.mat-radio-checked
  .mat-radio-inner-circle {
  position: relative;
}

.select-charity-section
  .mat-radio-button.mat-accent.mat-radio-checked
  .mat-radio-inner-circle:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: #eee;
}

.select-charity-section
  .mat-radio-button.mat-accent.mat-radio-checked
  .mat-radio-inner-circle:after {
  content: "";
  left: 10px;
  top: -1px;
  width: 15px;
  height: 32px;
  border: solid white;
  border-width: 0 6px 6px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
}

.select-charity-section .mat-radio-button .mat-radio-ripple {
  position: absolute;
  left: calc(50% - 14px);
  top: calc(50% - 14px);
  height: 28px;
  width: 28px;
  z-index: 1;
  pointer-events: none;
}

.charity-list-content h5 {
  color: #161616;
  font-family: Avenir;
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  margin-bottom: 15px;
}

.charity-list-content p {
  color: #7d7d7d;
  font-family: Avenir;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  margin-bottom: 8px;
}

.know-btn-area {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  bottom: 25px;
  right: 25px;
}

a.know-more-btn {
  color: #f97267;
  font-family: "Avenir Lt Std";
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-decoration: underline;
}

.charity-list-img img {
  width: 68px;
  height: 68px;
  border-radius: 50%;
}

.upload-label {
  color: #7d7d7d;
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  font-style: italic;
}

.select-charity-section mat-radio-button,
.two-column-input .form-group {
  width: 48.62%;
  margin-right: 2.75%;
}

.select-charity-section mat-radio-button:nth-child(2n),
.two-column-input .form-group:nth-child(2n) {
  margin-right: 0;
}

.two-column-input {
  display: flex;
  align-items: flex-start;
}

.profile-form .form-group.charity-form-group {
  margin-bottom: 40px !important;
}

.profile-pic .upload-profile-pic {
  position: absolute;
  right: 54px;
  bottom: 4px;
}

.form-group.new-form-btn button {
  width: 180px;
  height: 55px;
  font-family: "Avenir Lt Std";
}

.profile-form .form-group.new-form-btn {
  margin-top: 15px;
}

.edit-profile-heading {
  border-bottom: 2px solid #e6e6e6;
  margin-bottom: 25px;
}

.reset-password-section {
  position: relative;
}

.close-button {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 9;
  outline: none !important;
  font-size: 24px;
  color: #444444;
  padding: 0;
  font-weight: 500;
  transition: all 0.3s;
  cursor: pointer;
}

.close-button:hover {
  color: #f97267;
  transition: all 0.3s;
}
.change-password-modal {
  overflow: auto;
}
.buybookshop-ribbon {
  background: url(assets/img/bookshop-ribbon1.png) no-repeat;
  padding: 5px 10px;
  padding-right: 21px;
  height: 33px;
  margin-bottom: -10px;
  margin-left: -38px;
  z-index: 9;
  position: relative;
  background-size: cover;
  width: 119px;
  text-align: center;
}
.buybookshop-ribbon.purple-ribon {
  background: url(assets/img/bookshop-ribbon2.png) no-repeat;
}
.buybookshop-ribbon p {
  color: #ffffff;
  font-size: 10px;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.1px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.pupils-recommendedbook-list-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pupils-recommendedbook-list {
  margin: 2px 0;
  box-shadow: 0 6px 22px rgb(63 103 152 / 10%);
  border-radius: 5px;
  background-color: #ffffff;
  margin-bottom: 30px;
}


.pupils-recommendedbook-list:first-child {
  margin-left: 0;
}
.recommendedbook-list-img img {
  width: 100%;
}

.recommendedbook-list-content h3 {
  font-size: 18px;
  color: #323232;
  font-weight: 600;
  font-family: 'fsme';
  text-align: left;
  line-height: normal;
  margin-bottom: 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  height: 50px;
}

.recommendedbook-list-content {
  margin-top: 15px;
  font-family: "Avenir Lt std";
  padding: 10px;
  padding-top: 0;
}

.recommendedbook-list-content h4 {
  color: #494949;
  font-size: 15px;
  text-align: left;
  font-weight: 500;
  margin-bottom: 10px;
  font-family: Roboto;
}
h3.pupils-recommendedbook-title {
  color: #003430;
  font-family: "fsme";
  font-size: 26px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
}

.recommendedbook-list-content h5 {
  color: #494949;
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 5px;
}

.pupils-recommendedbook {
  padding-top: 15px;
}
.pupils-info-details ul {
  flex-wrap: wrap;
}

/* .pupils-info-details ul li {
  margin-bottom: 10px;
  width: 33.33% !important;
} */

.recommendedbook-list-img {
  height: 255px;
  background-color: #fff;
}

.recommendedbook-list-img img {
  height: 100%;
}
.recommendedbook-title {
  width: auto !important;
  height: auto !important;
}
.children-comment-section {
  padding: 35px 40px;
  max-width: 1200px;
  width: 100%;
  background: #fff;
  border: 1px solid #f2f2f2;
  border-radius: 10px;
}

.children-coment-content h3 {
  font-size: 25px;
  font-weight: 900;
  font-family: "Avenir Book";
  color: #f97267;
  margin-bottom: 20px;
}
.children-coment-content {
  margin-bottom: 30px;
}
.children-coment-content p {
  font-size: 18px;
  font-weight: 500;
  font-family: "Avenir LT Std";
  color: #494949;
  margin-bottom: 15px;
}
.mat-date1 input {
  width: 100%;
  color: #494949;
  font-family: "Avenir Lt std";
  font-size: 17px;
  font-weight: 500;
  display: flex;
  padding: 9px 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-left: 9px;
}

.pupils-info-content.book-title {
  text-align: right;
  margin-left: 19px;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.example-custom-date-class {
  background-color: #e9a9a4;
  border-color: #f97267;
  border-radius: 50%;
}

.comment_section12 mat-card {
  padding: 0;
}
.comment-detail-section {
  display: flex;
  align-items: flex-start;
  padding: 35px 33px;
  margin-bottom: 20px;
  box-shadow: 0 6px 22px rgb(63 103 152 / 10%);
  border-radius: 5px;
  background-color: #ffffff;
}

.commented-book-img {
  width: 295px;
}

.commented-book-img img {
  max-width: 100%;
  height: 200px;
}
.commented-book-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.commented-book-content h3 {
  color: #003430;
  font-size: 34px;
  line-height: 40px;
  text-align: left;
  font-family: 'fsme';
  margin-bottom: 15px;
  width: calc(100% - 150px);
}

.commented-user {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.commented-user img {
  width: 33px;
  height: 33px;
  border-radius: 50%;
  margin-right: 15px;
}

.commented-user h6 {
  color: #003430;
  font-size: 17px;
  line-height: 28px;
  text-align: left;
  font-family: 'fsme-regular';
  margin: 0;
}

.comment-qa-list {
  padding-bottom: 10px;
}

.comment-qa-list h5 {
  color: #003430;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  font-family: 'fsme';
  margin-bottom: 8px;
}
.comment-qa-list p {
  margin-bottom: 15px;
  color: #003430;
  font-size: 17px;
  line-height: 21px;
  text-align: left;
  font-family: 'fsme-regular';
}
.comment-qa-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.comment-qa-list ul li {
  background-color: #eeeeee;
  padding: 10px 25px;
  border-radius: 0;
  margin-right: 12px;
  color: #666666;
  font-size: 17px;
  font-weight: 500;
  line-height: 21px;
  font-family: "Avenir LT Std";
}
.commented-book-content {
  width: calc(100% - 295px);
  padding-left: 30px;
  padding-top: 15px;
}
.datepicker-section.input-full-width .form-control {
  box-sizing: border-box;
}

section.comment_section12 .custom-select,
.datepicker-section.input-full-width .form-control {
  height: 57px;
  border-radius: 8px;
  border: 1px solid #d4d4d4;
  background-color: #ffffff;
  color: #999999;
  font-family: 'fsme-regular';
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
}

section.comment_section12 .custom-select::-webkit-input-placeholder,
.datepicker-section.input-full-width .form-control::-webkit-input-placeholder {
  /* Edge */
  color: #838383;
  font-weight: 500;
  font-family: "Avenir LT Std";
}

section.comment_section12 .custom-select:-ms-input-placeholder,
.datepicker-section.input-full-width .form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #838383;
  font-weight: 500;
  font-family: "Avenir LT Std";
}

section.comment_section12 .custom-select::placeholder,
.datepicker-section.input-full-width .form-control::placeholder {
  color: #838383;
  font-weight: 500;
  font-family: "Avenir LT Std";
}
/* section.comment_section12 {
  background: #fff;
  box-shadow: 0 6px 22px rgb(63 103 152 / 10%);
  border-radius: 5px;
  background-color: #ffffff;
} */
section.comment_section12 .filter-section {
  padding: 30px 35px;
  box-shadow: 0 6px 22px rgb(63 103 152 / 10%);
  border-radius: 5px;
  background-color: #ffffff;
}
.input-full-width {
  width: 100%;
}
.inside-text-box {
  position: relative;
}
.form-control.datepicker-input {
  position: relative;
  z-index: 0;
}
.datepicker-section.input-full-width .form-control {
  display: flex;
  align-items: center;
}
.datepicker-section mat-datepicker-toggle {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}
.commented-book-title h5 {
  width: 90px;
  font-size: 17px;
  color: #666666;
  font-weight: 500;
  font-family: "Avenir LT Std";
}

.comment-detail-section.fixed-p {
  position: relative;
}

h5.fixed-title {
  position: absolute;
  right: 32px;
  margin-top: 11px;
  color: #003430;
  font-family: 'fsme-regular';
  font-size: 17px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 21px;
  text-align: left;
}
.serch-students {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.send-email-menu.mat-menu-panel.action-dropmenu {
  width: 104px;
}

/*************/
.Average-chart-bg {
  background: #FFFFFF;
  box-shadow: 0px 6px 22px rgba(63, 103, 152, 0.1);
  border-radius: 5px;
  overflow: hidden;
}

.average-chart {
  margin-top: 70px;
}

.average-chart h2 {/* display */
  font-family: 'fsme';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 26px;
/* identical to box height, or 100% */


/* on light/900 */
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 35px;
}
.above-list {
  padding: 0;
  margin: 0;
  list-style: none;
  width: 100%;
}

.above-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 7px;
}
.above-list li:last-child {
  margin: 0;
}

.above-list li .expactation-btn-Above {
  margin: 0;
  padding: 6px 16px;
}

.above-list li .expactation-btn-Expected {
  margin: 0;
  padding: 6px 16px;
}

.above-list li .expactation-btn-Below {
  margin: 0;
  /* padding: 6px 16px; */
}

.above-list li .expactation-btn-Risk {
  margin: 0;
  /* padding: 6px 16px; */
}

.above-list h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  font-family: 'fsme';
  margin: 0;
}
.diff-word h2 {
  font-family: 'fsme';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 26px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 35px;
}

.diff-word {
  margin-top: 70px;
}

.diff-word ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.diff-word ul li span {
  padding: 10px 25px;
  background: #F0F0E6;
  border-radius: 24px;
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}

.diff-word ul li {
  margin-bottom: 15px;
  margin-right: 13px;
}
.bad-book {
  display: flex;
  align-items: center;
}

.bad-book h4 {
  font-family: 'fsme';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
/* identical to box height */


/* color-06 */
  color: #003430;
  margin: 0;
  margin-left: 20px;
}
.book-table h2 {
  font-family: 'fsme';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 26px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 35px;
}

.book-table {
  margin-top: 60px;
}
h3.next-title {
  font-family: 'fsme';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 26px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 20px;
}
.fixed-icon {
  text-align: right;
}

.fixed-icon a {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  box-shadow: 0px 6px 22px rgb(63 103 152 / 10%);
  border-radius: 50%;
}


/* breadcrumb {
  position: absolute;
  z-index: 3;
  right: 3%;
  top: 127px;
} */

/* .teacher-breadcrumb .breadcrumb {
  position: absolute;
  z-index: 3;
  right: 3%;
  top: 66px;
  width: 200px;
} */

.pagination-section .mat-icon-button {
  background-color: #5aaf96;
  color: #fff;
}

.pagination-section .mat-button-disabled {
  background: #fff!important;
  color: #000 !important;
}
/****** new design-css ******/

/** Responsive Code **/

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .above-list li .expactation-btn-Below {
    width: auto;
    font-size: 14px;
}

.above-list li .expactation-btn-Expected {
    width: auto;
    font-size: 14px;
}

.above-list li .expactation-btn-Above {
    width: auto;
    font-size: 14px;
}

.above-list h4 {
    font-size: 18px;
}
  .average-chart h2 {
    margin-bottom: 20px;
    font-size: 20px;
  }
  .jas-list li h4 {
    word-break: break-all;
    font-size: 14px;
    padding-left: 8px;
  }

  .average-chart {
      margin-top: 50px;
  }

  .diff-word {
      margin-top: 50px;
  }

  .diff-word h2 {
      font-size: 20px;
      margin-bottom: 20px;
  }

  .diff-word ul li span {
      padding: 10px 18px;
      font-size: 15px;
  }

  .diff-word ul li {
      margin-bottom: 9px;
      margin-right: 8px;
  }

  .book-table h2 {
      font-size: 20px;
  }

  .book-table {
      margin-top: 50px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .above-list li .expactation-btn-Below {
    width: 100px;
  }

.above-list li .expactation-btn-Expected {
    width: 100px;
}

.above-list li .expactation-btn-Above {
    width: 100px;
}

.above-list h4 {
    font-size: 20px;
}
  .pupils-name-list {
    width: 40% !important;
    
  }
  .pupils-info-details {
    margin-left: 8px;
  }
  .jas-list li h4 {
    font-size: 14px;
  }
  .average-chart h2 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .average-chart {
    margin-top: 50px;
  }

  .diff-word h2 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .diff-word {
    margin-top: 50px;
  }

  .diff-word ul li span {
    padding: 10px 16px;
  }

  .diff-word ul li {
    margin-bottom: 9px;
    margin-right: 5px;
  }

  .book-table {
    margin-top: 50px;
  }

  .book-table h2 {
    font-size: 20px;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1365px) {
  .pupils-info-details ul li {
    width: 47% !important;
    padding: 9px 20px !important;
    margin-bottom: 7px !important;
  }
  .pupils-name-list {
    width: 32% !important;
    padding: 20px 20px;
  }
  .pupils-info-details {                                                                                                                                  
    margin-left: 8px;
  }
  .jas-list li h4 {
    font-size: 14px;
  }
  .average-chart h2 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .average-chart {
    margin-top: 50px;
  }

  .diff-word h2 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .diff-word {
    margin-top: 50px;
  }

  .diff-word ul li span {
    padding: 10px 16px;
  }

  .diff-word ul li {
    margin-bottom: 9px;
    margin-right: 5px;
  }

  .book-table {
    margin-top: 50px;
  }

  .book-table h2 {
    font-size: 20px;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1800px) {
  .pupils-info-details ul {
    flex-wrap: wrap;
  }

  .pupils-info-details ul li {
    margin-bottom: 10px;
    width: 33.33% !important;
  }
  .buy-book-content h3 {
    font-size: 17px;
  }
  .subcat-book-section .buy-book-content h3 {
    height: 40px;
  }
  .buy-bundle-section .bundle-book h3 {
    font-size: 25px;
  }
  .bundle-ribon p {
    font-size: 18px;
  }
  h4.bundle-price {
    font-size: 25px;
  }
  .dashborad-full-wrapper .bundle-big-img {
    padding: 50px 70px 70px;
  }
  .book-shop-content h3 {
    font-size: 30px;
  }
  .banner-content h1 {
    font-size: 40px;
  }
  .school-login-section,
  .school-signup-section,
  .school-forgot-password {
    margin-top: 100px;
  }
  .cart-detail .cart-table tr th:nth-child(3),
  .cart-detail .cart-table tr th:nth-child(4) {
    width: 145px;
  }
  .cart-table tr td {
    padding: 25px 20px;
  }
  .cart-detail .cart-table tr th:nth-child(5) {
    width: 120px;
  }
  .pupils-detail-content h3 {
    font-size: 30px;
    line-height: 32.6px;
    margin-bottom: 18px;
  }
  /* .pupils-detail-content {
    margin-left: 18px;
  } */
  .pupils-info-details {
    padding-left: 0;
  }
  .pupils-info-icon img {
    width: 50px;
  }
  .pupils-info-content {
    font-size: 22px;
    line-height: 31.5px;
  }
  .know-btn-area {
    bottom: 18px;
    right: 20px;
  }
  .charity-list-content h5 {
    margin-bottom: 10px;
  }
  .login-right-section .login-body h2 {
    font-size: 48px;
    line-height: 48px;
    margin-bottom: 13px;
  }
  .login-right-section .login-body p {
    margin-bottom: 30px;
  }
  .profile-pic .upload-profile-pic {
    right: 30px;
  }
  .comment-qa-list p {
    font-size: 16px;
  }

  .commented-book-content h3 {
    font-size: 30px;
    margin-bottom: 11px;
    line-height: 36px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1690px) {
  .pupils-info-details ul {
    flex-wrap: wrap;
  }

  .pupils-info-details ul li {
    margin-bottom: 10px;
    width: 33.33% !important;
  }
  .banner-inner {
    padding: 70px 0 50px;
  }
  .banner-content h1 {
    font-size: 42px;
  }
  .login-section,
  .forgot-password-section,
  .reset-password-section {
    padding: 110px 0;
  }
  .signup-section {
    padding: 80px 0;
  }
  .banner-inner {
    width: 60%;
  }
  .bundle-briefing ul li p {
    font-size: 13px;
  }
  .review-details p {
    font-size: 17px;
  }
  .buy-book-section mat-card {
    padding: 25px 18px;
  }
  .buy-book-content h4 .final-price {
    font-size: 18px;
  }
  .buy-book-content h4 del,
  .buy-book-content h4 .discount {
    font-size: 14px;
  }
  .buy-book-btn {
    letter-spacing: 1px;
  }
  .buy-bundle-section .bundle-book h3 {
    font-size: 22px;
  }
  .bundle-book {
    min-height: 375px;
  }
  .bundle-ribon {
    background: #3f3f3f;
    position: relative;
    margin-left: -41px;
    height: auto;
    height: 49px;
  }
  .bundle-ribon:before {
    content: "";
    background: url(./assets/img/bundle-ribbon-before.png) no-repeat;
    width: 7px;
    height: 59px;
    position: absolute;
    left: -1px;
    top: 1px;
    z-index: -9;
  }
  .bundle-ribon:after {
    content: "";
    background: url(./assets/img/bundle-ribbon-after.png) no-repeat;
    width: 27px;
    height: 49px;
    position: absolute;
    right: -25px;
    top: 0;
    z-index: 9;
  }
  .page-header h2 {
    font-size: 32px;
  }
  .bundle-img {
    padding-left: 25px;
  }
  .section-cat-heading h4 {
    font-size: 18px;
  }
  .statscard-img {
    width: 35%;
    padding-right: 15px;
  }
  .statscard-img img {
    max-width: 100%;
  }
  .dashboard-stats-card .statscard-content {
    width: 65%;
  }
  .dashboard-stats mat-card {
    padding: 20px 25px !important;
  }
  .dashborad-full-wrapper .bundle-big-img {
    padding: 50px 55px 70px;
  }
  .book-shop-detail {
    padding: 40px 50px;
  }
  .dashboard-inner-wrapper {
    width: 96%;
    padding: 30px 25px 80px;
  }
  .key-stage-content h3 {
    font-size: 20px;
  }
  .user-profile img {
    width: 150px;
    height: 150px;
  }
  .error-logo {
    top: 60px;
  }
  .error-section {
    min-height: 900px;
  }
  .error-field p {
    font-size: 40px;
    margin-bottom: 20px;
  }
  .error-content {
    bottom: 70px;
  }
  .bundle-briefing ul {
    flex-wrap: wrap;
  }
  .bundle-briefing ul li {
    padding-bottom: 10px;
  }
  .cart-detail .cart-table {
    overflow: hidden;
    min-width: 800px;
  }
  .cart-detail {
    width: 100%;
    overflow: scroll;
  }
  .bundle-img-section .bundle-img img {
    max-height: 225px;
  }
  .pupils-stats mat-card {
    min-height: 160px;
  }
  .pupils-detail-img img {
    width: 85px;
    height: 85px;
  }
  .pupils-detail-content h3 {
    font-size: 27px;
    line-height: 28.6px;
    margin-bottom: 14px;
  }
  .pupils-info-icon img {
    width: 45px;
    margin-right: 7px;
  }
  .pupils-info-content {
    font-size: 15px;
    line-height: 28.5px;
  }
  .pupils-detail-content p {
    font-size: 16px;
    line-height: 20.3px;
  }
  .pupils-detail-content p span {
    width: 20px;
  }
  .pupils-name-list {
    padding: 16px 18px;
  }
  .dashboard-inner-wrapper.edit-school-wrapper {
    width: 100%;
    padding: 30px 20px 50px;
  }
  .upload-profile-pic img {
    width: 45px;
    height: 45px;
  }
  .profile-pic .upload-profile-pic {
    right: 25px;
  }
  .select-charity-section mat-radio-button {
    padding: 20px 15px;
    height: 190px;
  }
  .charity-list-content p {
    font-size: 14px;
    line-height: 19px;
  }
  .charity-list-content h5 {
    font-size: 16px;
  }
  .login-body.reset-password-body h2 {
    font-size: 30px;
    line-height: 40px;
  }
  .sidenav-area ul li a {
    padding: 11px 15px;
  }
  .buybookshop-ribbon {
    margin-left: -34px;
    padding: 5px 11px;
    padding-right: 21px;
  }
  section.comment_section12 .filter-section {
    padding: 20px 25px;
  }

  .comment-detail-section {
    padding: 25px 25px;
  }

  .commented-book-content h3 {
    width: calc(100% - 125px);
    font-size: 27px;
    line-height: 33px;
  }

  .commented-book-content {
    padding-left: 25px;
  }

  section.comment_section12 {
    padding: 25px 30px;
  }

  .comment-qa-list p {
    margin-bottom: 12px;
  }

  .commented-user {
    margin-bottom: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1550px) {
  .menu-list ul li {
    margin-right: 17px !important;
  }
  .menu-list ul li a {
    font-size: 15px !important;
  }
  section.success-order-section {
    padding: 70px 0;
  }
  .success-order-field h1 {
    font-size: 40px;
    line-height: 44px;
    margin-bottom: 30px;
  }
  .success-order-field a {
    width: 400px;
    height: 50px;
    font-size: 16px;
  }
  h2.section-title {
    font-size: 35px;
    margin-bottom: 35px;
  }
  .bundle-book h3 {
    height: 77px;
  }
  .bundle-ribon p {
    font-size: 18px;
  }
  .bundle-book h3 {
    font-size: 30px;
  }
  .bundle-img {
    padding-left: 26px;
  }
  h4.bundle-price {
    font-size: 24px;
  }
  .banner-content h1 {
    font-size: 38px;
    margin-bottom: 20px;
  }
  .banner-img {
    padding-left: 20px;
  }
  .banner-content h3 {
    font-size: 27px;
    margin-bottom: 25px;
  }
  .membership-plan-section {
    padding: 70px 0;
  }
  .mat-heading h2.heading {
    font-size: 35px;
  }
  mat-card.mat-heading {
    margin-bottom: 35px;
  }
  .membership-list mat-card-header {
    height: 70px;
  }
  .membership-list .memberlist-title {
    font-size: 20px;
  }
  .membership-list .membership-price {
    font-size: 45px;
  }
  .membership-list .plan-limit {
    font-size: 16px;
  }
  .membership-list .plan-noti {
    font-size: 19px;
  }
  .membership-list .mat-grey-body {
    padding: 20px 16px 30px;
  }
  .membership-list mat-card-content ul li {
    font-size: 16px;
    padding: 13px 13px;
  }
  .membership-btn {
    height: 50px;
    font-size: 16px;
  }
  .bundle-big-img {
    padding: 50px 80px 70px;
  }
  .bundle-full-description h2 {
    font-size: 25px;
  }
  .bundle-full-description p,
  .bundle-full-description ul li {
    font-size: 15px;
  }
  .bundle-briefing ul li {
    padding: 0 15px;
  }
  .dashborad-full-wrapper .bundle-briefing ul li {
    padding: 0 10px;
  }
  .review-details p {
    font-size: 16px;
    line-height: 24px;
  }
  .user-profile h3 {
    font-size: 24px;
    line-height: 26px;
  }
  .user-profile img {
    width: 150px;
    height: 150px;
  }
  .user-profile {
    padding: 50px 35px;
  }
  .user-details-field h3 {
    font-size: 18px;
  }
  .user-details-field .membership-table table th {
    font-size: 16px;
  }
  .user-details-field .membership-table table td {
    font-size: 16px;
  }
  .profile-section {
    padding: 50px 0;
  }
  .dashboard-profile-section {
    padding: 0;
    padding-bottom: 20px;
  }
  .user-profile .upload-profile-pic img {
    width: 40px;
    height: 40px;
  }
  .checkout-oder-list ul li img {
    width: 55px;
    height: 70px;
  }
  .checkout-oder-list ul li {
    font-size: 14px;
    padding-right: 10px;
    line-height: 17px;
  }
  .checkout-oder-list ul li:last-child {
    padding-right: 0;
  }
  .checkout-oder-list {
    padding: 12px 15px;
  }
  .checkout-pricing {
    padding-top: 20px;
  }
  .checkout-pricing ul li {
    padding: 15px 0;
  }
  .login-section,
  .forgot-password-section,
  .reset-password-section,
  .signup-section {
    padding: 70px 0;
  }
  .inner-wrapper {
    width: 90%;
  }
  .buy-book-section mat-card {
    padding: 25px 15px;
  }
  mat-sidenav.mat-drawer.mat-sidenav {
    max-width: 240px;
  }
  .sidebar-content {
    width: calc(100% - 240px) !important;
  }
  .buy-book-img {
    width: 110px;
    height: 125px;
  }
  .wishlist-icon {
    right: 10px;
    top: 5px;
  }
  .buy-bundle-section .bundle-book h3 {
    font-size: 20px;
  }
  a.bundle-shop-btn {
    width: 135px;
    height: 45px;
  }
  .sidenav-area ul li a {
    font-size: 16px;
    padding: 10px 15px;
  }
  .bundle-ribon p {
    font-size: 17px;
  }
  .buy-book-row {
    flex-wrap: wrap;
  }
  .buy-book-row mat-card {
    max-width: 23.5% !important;
    margin-right: 2% !important;
    flex: 1 1 23.5% !important;
  }
  .buy-book-row mat-card:nth-child(4n) {
    margin-right: 0% !important;
  }
  .sidebar-header .menu-list ul li:last-child {
    padding-right: 0;
  }
  .dashboard-inner-wrapper {
    width: 98%;
    padding: 30px 20px 80px;
  }
  .key-stage-detail {
    height: 200px;
    padding: 30px 20px;
  }
  .key-stage-content h3 {
    font-size: 19px;
  }
  .filter-section .filter-button,
  .filter-section .custom-select,
  .filter-section .dateRange {
    padding: 10px 14px;
    font-size: 15px;
  }
  .filter-title {
    margin-right: 15px !important;
  }
  .filter-section li.or-seperate {
    font-size: 15px;
    padding: 0 5px;
  }
  .order-datatable .table-content {
    font-size: 15px;
    padding: 10px 15px !important;
  }
  .order-datatable .table-book-field,
  .order-datatable .table-book-field {
    min-width: 350px;
  }
  .dashboard-profile-info-card {
    max-width: 25% !important;
    flex: 1 1 25% !important;
  }
  .subcat-title .subcat-title-left h3,
  .teacher-title h3 {
    font-size: 25px;
  }
  .error-logo {
    top: 40px;
  }
  .error-field p {
    font-size: 33px;
    margin-bottom: 20px;
  }
  .page-back-btn {
    min-width: 250px !important;
    height: 53px;
    font-size: 20px;
  }
  .error-content {
    bottom: 45px;
  }
  .error-section {
    min-height: 750px;
  }
  .cart-table tr td {
    padding: 18px 10px;
  }
  .cart-detail .cart-table tr th:nth-child(3),
  .cart-detail .cart-table tr th:nth-child(4) {
    width: 115px;
  }
  .cart-detail .cart-table tr th:nth-child(5) {
    width: 95px;
  }
  .cartproduct-quantity .button-container .btn {
    height: 38px;
  }
  .cartproduct-quantity input.qty {
    height: 38px;
    font-size: 18px;
    width: 40px;
  }
  .cart-table .cart-product-detail .cart-img img,
  .cart-img img {
    max-width: 60px;
  }
  .cart-table .cart-product-detail .cart-content {
    padding-left: 10px;
  }
  .bold-font,
  .membership-list mat-card-content ul li.bold-font {
    font-size: 18px;
  }
  .pupils-stats mat-card {
    min-height: 140px;
    padding: 16px 20px;
  }
  .pupils-stats-card .pupils-content h3 {
    font-size: 26px;
  }
  .pupils-stats-card .pupils-content p {
    font-size: 16px;
    height: 53px;
  }
  .checkbox-table .table thead th {
    font-size: 15px;
    line-height: 17.6px;
    padding: 8px 10px 4px;
  }
  .checkbox-table .table tr td {
    line-height: 17.6px;
    font-size: 15px;
    padding: 10px 10px;
  }
  .expactation-btn {
    font-size: 15px;
  }
  .checkbox-table .table th.mat-header-cell:first-of-type,
  .checkbox-table .table td.mat-cell:first-of-type,
  .checkbox-table .table td.mat-footer-cell:first-of-type {
    padding-left: 17px !important;
  }
  .checkbox-table .table th.mat-header-cell:last-of-type,
  .checkbox-table .table td.mat-cell:last-of-type,
  .checkbox-table .table td.mat-footer-cell:last-of-type {
    padding-right: 17px !important;
  }
  .employee-img-name {
    min-width: 220px;
  }
  .checboxTable.checkbox-table {
    overflow: hidden;
    overflow-x: auto;
  }
  .checboxTable.checkbox-table .table {
    width: 100%;
  }
  .custom-selectbox {
    width: 90px;
    padding: 5px 15px;
  }

  .custom-selectbox.c-sb {
    width: 105px;
    padding: 5px 9px;
  }
  .pupils-info-details ul li {
    padding: 0 10px;
  }
  .pupils-info-icon {
    font-size: 14px;
    line-height: 17.6px;
  }
  .pupils-detail-content h3 {
    font-size: 24px;
    line-height: 25.6px;
    margin-bottom: 12px;
  }
  .pupils-detail-content p {
    font-size: 15px;
    line-height: 18.3px;
  }
  .pupils-info-icon img {
    width: 42px;
    margin-right: 7px;
  }
  .login-right-section .login-body h2 {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 13px;
  }
  .login-right-section .login-body p {
    margin-bottom: 25px;
  }
  /* .login-right-section .login-body {
    margin-left: 80px;
  } */
  .select-charity-section mat-radio-button {
    padding: 17px 12px;
    height: 215px;
  }
  .charity-list-content h5 {
    font-size: 16px;
    line-height: 18px;
  }
  .charity-list-content {
    margin-left: 10px;
  }
  .select-charity-section .mat-radio-label .mat-radio-container {
    margin-right: 2px;
  }
  .buybookshop-ribbon {
    margin-left: -29px;
    padding: 5px 11px;
    padding-right: 21px;
  }
  .pupils-info-details ul {
    flex-wrap: wrap;
  }

  .pupils-info-details ul li {
    margin-bottom: 10px;
    width: 33.33% !important;
  }
  .recommendedbook-list-img {
    height: 210px;
  }
  .pupils-recommendedbook h3 {
    line-height: 24px;
    margin-bottom: 10px;
    font-size: 17px;
    height: 48px;
  }
  .children-comment-section {
    padding: 30px 24px;
  }

  .children-coment-content h3 {
    font-size: 21px;
    margin-bottom: 12px;
  }

  .children-coment-content p {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .children-coment-content {
    margin-bottom: 17px;
  }
  .commented-book-img {
    width: 200px;
  }

  .commented-book-content {
    width: calc(100% - 260px);
    padding-top: 5px;
  }

  .commented-user {
    margin-bottom: 20px;
  }

  .comment-qa-list h5 {
    font-size: 18px;
    line-height: 23px;
  }

  .commented-book-title h5 {
    font-size: 16px;
    width: 90px;
    text-align: right;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1420px) {
  .banner-inner {
    width: 69%;
    padding: 55px 0 40px;
  }
  .banner-img img {
    max-width: 100%;
  }
  .membership-plan-section {
    padding: 60px 0;
  }
  .buy-bundle-row {
    flex-wrap: wrap;
  }
  .bundle-book h3 {
    height: 65px;
  }
  .bundle-book {
    min-height: 315px;
    padding: 30px 25px 25px;
  }
  .buy-bundle-section.bundle-section .buy-bundle-row mat-card {
    margin-right: 2% !important;
    max-width: 49% !important;
    flex: 1 1 49% !important;
  }
  .buy-bundle-section.bundle-section .buy-bundle-row mat-card:nth-child(2n) {
    margin-right: 0% !important;
  }
  .dashboard-stats-card .statscard-content p {
    font-size: 15px;
  }
  .dashboard-stats mat-card {
    height: 125px;
  }
  .dashborad-full-wrapper .bundle-big-img {
    padding: 40px 30px 55px;
  }
  .book-shop-detail {
    padding: 22px 30px;
  }
  .book-shop-img img {
    width: 120px;
  }
  .book-shop-content h3 {
    font-size: 25px;
  }
  .key-stage-detail {
    height: 180px;
    padding: 25px 20px;
  }
  .filter-section ul {
    padding: 15px 10px;
  }
  .table-search-field {
    padding: 0 15px;
  }
  .filter-section .filter-button,
  .filter-section .custom-select,
  .filter-section .dateRange {
    font-size: 14px;
    padding: 10px 10px;
  }
  .filter-title {
    margin-right: 10px !important;
  }
  .order-datatable .table-book-field,
  .order-datatable .table-book-field {
    min-width: 300px;
  }
  .dashboard-profile-info-card {
    max-width: 30% !important;
    flex: 1 1 30% !important;
  }
  .shop-detail-section .dashboard-inner-wrapper.dashborad-full-wrapper {
    padding: 30px 20px 80px;
  }
  .error-section {
    min-height: 700px;
  }
  .error-logo {
    top: 20px;
  }
  .error-field p {
    font-size: 27px;
    margin-bottom: 15px;
  }
  .error-content {
    bottom: 25px;
  }
  .page-back-btn {
    min-width: auto !important;
    height: auto;
    font-size: 16px;
    padding: 7px 30px !important;
  }
  .bundle-ribon {
    margin-left: -31px;
  }
  .place-order-section {
    padding: 45px 15px;
  }
  .place-order-section .btn {
    font-size: 16px;
    height: 53px;
  }
  .place-order-section li.total-title {
    font-size: 16px;
  }
  .place-order-section li.total-price span {
    font-size: 21px;
  }
  .cart-table h4 {
    font-size: 16px;
  }
  .order-detail-table table td {
    font-size: 15px;
    padding: 15px 10px;
    border-bottom: 0;
  }
  .order-detail-table table th {
    padding: 11px 10px;
  }
  .order-detail-section h3 {
    font-size: 18px;
  }
  .order-billing-details p {
    font-size: 15px;
  }
  .order-detail-pricing .checkout-pricing p {
    font-size: 15px;
  }
  h3.order-detail-id {
    font-size: 20px;
  }
  .bundle-name-logo {
    top: -27px;
    right: -22px;
  }
  .bundle-img-section .bundle-img {
    margin-top: 30%;
  }
  .bundle-img-section .bundle-img img {
    max-height: 175px;
  }
  .pupils-stats-card .pupils-content h3 {
    font-size: 23px;
  }
  .pupils-stats mat-card {
    padding: 15px 18px;
  }
  .pupils-stats-card .pupils-content p {
    font-size: 15px;
    height: 50px;
  }
  .pupils-stats mat-card {
    min-height: 125px;
  }
  section.table-section h4 {
    font-size: 20px;
  }
  .checkbox-table tr td {
    font-size: 15px;
  }
  .pupils-info-details ul {
    flex-wrap: wrap;
  }
  .pupils-info-details ul li.pupils-age {
    width: 25%;
  }
  .pupils-info-details ul li {
    width: 48% !important;
    padding: 0 20px;
    margin-bottom: 7px;
  }
  /* .pupils-info-details ul li:last-child {
    border-right: 1px solid #ececec;
  } */
  .pupils-info-details {
    width: 65%;
    padding: 0px 10px;
  }
  .pupils-name-list {
    width: 25%;
    padding: 35px 20px;
}
  .pupils-info-icon {
    font-size: 15px;
    line-height: 17.6px;
  }
  .pupils-info-icon img {
    width: 42px;
    margin-right: 9px;
  }
  .pupils-detail-content h3 {
    font-size: 22px;
    line-height: 24.6px;
    margin-bottom: 15px;
  }
  .pupils-detail-content p {
    font-size: 15px;
    line-height: 18.3px;
  }
  .select-charity-section mat-radio-button {
    padding: 17px 12px;
    height: 245px;
  }
  .profile-pic .upload-profile-pic {
    right: 15px;
  }
  .edit-profile-heading {
    margin-bottom: 20px;
  }
  .dashboard-inner-wrapper .profile-form .edit-profile-heading h3 {
    margin-bottom: 20px;
  }
  .login-right-section .login-body .form-group {
    margin-bottom: 18px;
  }
  .login-left-section .logo-item img {
    width: 125px;
  }
  .login-right-section .login-body h2 {
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 13px;
  }
  .new-login-btn {
    height: 47px;
  }
  .commented-book-content h3 {
    font-size: 24px;
    line-height: 30px;
  }

  .comment-qa-list h5 {
    font-size: 17px;
    line-height: 22px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1300px) {
  .checkout-order {
    padding: 20px 15px;
  }
  table.payment-table td {
    padding: 13px 10px;
  }
  .order-placed-btn {
    height: 50px;
    font-size: 16px;
  }
  .checkout-order h3 {
    font-size: 22px;
  }
  .banner-content h1 {
    font-size: 40px;
  }
  .banner-content h3 {
    font-size: 25px;
    line-height: 35px;
  }
  .banner-content a.banner-shop-btn {
    height: 50px;
    font-size: 14px;
  }
  .bundle-book h3 {
    font-size: 25px;
  }
  a.bundle-shop-btn {
    height: 45px;
    width: 160px;
  }
  .mat-heading h2.heading {
    font-size: 32px;
  }
  mat-card.mat-heading {
    margin-bottom: 25px;
  }
  .membership-list mat-card-content {
    padding: 0 15px;
  }
  .membership-list .membership-price {
    font-size: 40px;
  }
  .membership-list mat-card-content ul li {
    padding: 10px 15px;
  }
  .bundle-briefing ul {
    flex-wrap: wrap;
  }
  .bundle-briefing ul li {
    margin-bottom: 7px;
  }
  h2.section-title {
    font-size: 30px;
    margin-bottom: 30px;
  }
  .review-details {
    height: 420px;
  }
  .success-order-field h1 {
    font-size: 35px;
    line-height: 38px;
  }
  li.big-checkout-btn {
    display: none;
  }
  .dashboard-stats mat-card {
    padding: 20px 12px !important;
  }
  .dashboard-stats-card .statscard-content p {
    font-size: 14px;
  }
  .dashboard-stats-card .statscard-content h3 {
    font-size: 25px;
  }
  .dashboard-stats mat-card {
    height: 110px;
  }
  .buy-book-content h3 {
    font-size: 16px;
  }
  .subcat-book-section .buy-book-content h3 {
    height: 38px;
  }
  .buy-book-btn {
    height: 40px;
  }
  .page-header h2 {
    font-size: 27px;
  }
  .sidebar-content {
    padding-left: 30px;
    padding-right: 30px;
  }
  .dashborad-full-wrapper .bundle-big-img {
    padding: 30px 20px 45px;
  }
  .dashboard-inner-wrapper {
    width: 98%;
    padding: 30px 0px 80px;
  }
  .checkout-product-block {
    max-width: 45% !important;
    flex: 1 1 45% !important;
  }
  .order-datepicker {
    width: 110px;
  }
  .filter-title {
    font-size: 16px;
    margin-right: 6px !important;
  }
  .order-datepicker .mat-datepicker-toggle button {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
  .order-datatable .table-book-field,
  .order-datatable .table-book-field {
    min-width: 250px;
  }
  .order-datatable .table-head {
    font-size: 15px;
    padding: 10px 10px !important;
  }
  .order-datatable .table-content {
    padding: 10px 10px !important;
  }
  .subcat-title-right .mat-radio-button ~ .mat-radio-button {
    margin-left: 14px;
  }
  .order-detail-table {
    overflow: hidden;
    overflow-x: scroll;
  }
  .order-detail-table table {
    min-width: 700px;
  }
  .order-billing-details {
    padding: 25px 20px;
  }
  .menu-list ul li .table-search-field {
    width: 100%;
    margin: 0 !important;
  }
  .class-shortname {
    width: 36px;
    height: 36px;
    font-size: 14px;
  }
  .table-action-field {
    width: 115px;
    text-align: center !important;
  }
  .checkbox-table .table thead th {
    font-size: 14px;
    line-height: 16.6px;
    padding: 8px 8px 4px;
    min-width: 105px;
  }
  .checkbox-table .table tr td {
    line-height: 16.6px;
    font-size: 14px;
    padding: 10px 8px;
  }
  .expactation-btn {
    font-size: 14px;
    padding: 6px 16px;
  }
  .pupils-info-details ul {
    flex-wrap: wrap;
  }
  .pupils-info-details ul li.pupils-age {
    width: 25%;
  }
  .pupils-info-details ul li {
    width: 48% !important;
    padding: 8px 20px;
    margin-bottom: 5px;
  }
  .pupils-info-details ul li:nth-child(2n) {
    border-right: 0;
  }
  .pupils-info-details {
    width: 65%;
    padding: 0px 10px;
  }
  .pupils-name-list {
    width: 35%;
    padding: 20px 20px;
  }
  .pupils-info-icon {
    font-size: 15px;
    line-height: 17.6px;
  }
  .pupils-info-icon img {
    width: 40px;
    margin-right: 5px;
  }
  .pupils-detail-content h3 {
    font-size: 22px;
    line-height: 24.6px;
    margin-bottom: 15px;
  }
  .pupils-detail-content p {
    font-size: 15px;
    line-height: 18.3px;
  }
  .edit-school-wrapper .user-profile {
    padding: 35px 15px;
  }
  .select-charity-section mat-radio-button {
    width: 100%;
    height: auto;
    margin-right: 0;
  }
  .know-btn-area {
    position: unset;
  }
  .login-body.reset-password-body h2 {
    font-size: 27px;
    line-height: 40px;
  }
  .recommendedbook-list-img {
    height: 180px;
  }
  .pupils-recommendedbook h3 {
    line-height: 22px;
    font-size: 16px;
    margin-bottom: 8px;
    height: 45px;
  }
  .commented-book-img {
    width: 210px;
  }

  .commented-book-content {
    width: calc(100% - 210px);
    padding-top: 5px;
    padding-left: 20px;
  }

  .commented-user {
    margin-bottom: 16px;
  }

  .commented-book-content h3 {
    font-size: 20px;
    line-height: 27px;
  }

  .comment-qa-list p {
    font-size: 15px;
    line-height: 19px;
  }

  .comment-qa-list ul li {
    padding: 6px 18px;
    line-height: 19px;
    font-size: 15px;
    margin-right: 8px;
  }

  .commented-book-title h5 {
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .menu-list ul li {
    margin-right: 15px !important;
  }
  .inner-wrapper {
    width: 95%;
  }
  .menu-list ul li a {
    font-size: 14px !important;
  }
  .theme-btn.checkout-btn {
    width: 140px !important;
    height: 45px !important;
    font-size: 14px !important;
  }
  .login-body {
    width: 450px;
    padding: 40px 35px;
  }
  .login-section,
  .forgot-password-section,
  .reset-password-section,
  .signup-section {
    padding: 75px 0;
  }
  .login-body h2 {
    font-size: 27px;
  }
  .login-body p {
    font-size: 16px;
  }
  .forgot-password-section .login-body {
    padding: 40px 35px 50px;
  }
  .user-profile {
    padding: 50px 20px;
  }
  .user-profile img {
    width: 120px;
    height: 120px;
  }
  .user-profile h3 {
    font-size: 21px;
  }
  .user-profile p {
    font-size: 16px;
  }
  table.payment-table {
    max-width: 550px;
  }
  .membership-list mat-card-header {
    height: 55px;
  }
  .membership-list .mat-grey-body {
    padding: 15px 16px 25px;
    margin-top: 10px !important;
    margin-bottom: 13px;
  }
  .membership-cancel button {
    width: auto;
    padding: 9px 30px;
    font-size: 14px;
  }
  .membership-list mat-card-content ul {
    padding: 10px 0;
  }
  .membership-list mat-card-content ul li {
    padding: 10px 10px;
    line-height: 23px;
  }
  .banner-content h1 {
    font-size: 36px;
  }
  .bundle-full-description h2 {
    font-size: 22px;
  }
  .bundle-full-description p.author {
    margin-bottom: 15px;
  }
  .bundle-full-description h3.price {
    font-size: 22px;
  }
  .bundle-full-description p,
  .bundle-full-description ul li {
    font-size: 14px;
    margin-bottom: 12px;
  }
  table.payment-table td {
    padding: 13px 5px;
  }
  table.payment-table td:last-child {
    width: 55px;
  }
  .success-order-field h1 {
    font-size: 32px;
    line-height: 33px;
    margin-bottom: 20px;
  }
  .success-order-field p {
    margin-bottom: 30px;
  }
  section.success-order-section {
    padding: 60px 0;
  }
  .dashboard-stats .dashboard-stats-row mat-card {
    max-width: 32% !important;
    flex: 1 1 32% !important;
    margin-right: 2%;
  }
  .dashboard-stats .dashboard-stats-row mat-card:nth-child(3n) {
    margin-right: 0;
  }
  .buy-book-row mat-card,
  .buy-book-row mat-card:nth-child(4n) {
    max-width: 32% !important;
    margin-right: 2% !important;
    flex: 1 1 32% !important;
  }
  .buy-book-row mat-card:nth-child(3n) {
    margin-right: 0 !important;
  }
  a.bundle-shop-btn {
    height: 42px;
    width: 135px;
  }
  h4.bundle-price {
    font-size: 20px;
  }
  .dashborad-full-wrapper .bundle-big-img {
    padding: 20px 15px 35px;
  }
  .book-shop-detail {
    padding: 25px 10px;
  }
  .book-shop-content {
    width: 50%;
  }
  .book-shop-img {
    width: 50%;
    padding-left: 20px;
  }
  .book-shop-img img {
    max-width: 100%;
  }
  .book-shop-content h3 {
    font-size: 22px;
  }
  .checkout-product-block {
    max-width: 47% !important;
    flex: 1 1 47% !important;
  }
  .dashboard-inner-wrapper {
    width: 100%;
    padding: 30px 0px 80px;
  }
  .key-stage-detail {
    height: 135px;
    padding: 15px 15px;
  }
  .key-stage-content h3 {
    font-size: 16px;
  }
  .key-stage-content {
    padding-top: 15px;
  }
  .filter-section {
    flex-direction: column;
  }
  .filter-section mat-card {
    max-width: 100% !important;
    flex: 1 1 100% !important;
  }
  .filter-section .filter-button,
  .filter-section .custom-select,
  .filter-section .dateRange {
    padding: 10px 15px;
  }
  .table-search-field {
    width: 60%;
    margin-left: auto;
    margin-bottom: 20px !important;
    max-width: 330px;
  }
  .ordered-table {
    overflow-x: auto;
  }
  .order-datatable {
    width: 960px;
  }
  .subcat-title-right .mat-radio-label-content {
    padding-left: 10px;
  }
  .subcat-title-right .table-search-field {
    padding: 0 10px;
    padding-right: 0;
  }
  .table-search-box .search-input {
    padding: 0 10px !important;
    padding-right: 37px !important;
  }
  .subcat-title-right .mat-radio-label-content {
    padding-left: 4px;
  }
  .subcat-title-right .table-search-box {
    margin-bottom: 0;
  }
  .cart-detail {
    overflow: hidden;
    overflow-x: scroll;
  }
  .cart-detail .cart-table {
    overflow: hidden;
    min-width: 850px;
    width: 100%;
  }
  .cart-table tr td {
    padding: 18px 15px;
  }
  .cart-detail .cart-table tr th {
    font-size: 16px;
  }
  .cart-table h4 {
    font-size: 16px;
  }
  .cart-table .cart-product-detail .cart-content {
    padding-left: 15px;
  }
  .place-order-section {
    padding: 40px 15px;
  }
  .place-order-section .btn {
    font-size: 18px;
    height: 54px;
  }
  .place-order-section li.total-title {
    font-size: 16px;
  }
  .place-order-section li.total-price span {
    font-size: 25px;
  }
  .bundle-book h3 {
    height: 65px;
  }
  .bundle-img {
    padding-left: 30px;
  }
  .error-img img {
    max-width: 70%;
  }
  .error-logo {
    top: 40px;
  }
  .error-content {
    bottom: 60px;
  }
  .cart-left {
    max-width: 100% !important;
    flex: 1 1 100% !important;
  }
  .cart-right {
    max-width: 283px !important;
    margin-left: auto;
  }
  .order-detail-left {
    max-width: 100% !important;
    flex: 1 1 100% !important;
  }
  .order-detail-right {
    max-width: 100% !important;
    flex: 1 1 100% !important;
    margin-top: 50px;
  }
  .order-billing-details {
    padding: 34px 33px;
  }
  .order-billing-details p {
    font-size: 16px;
  }
  .order-detail-field {
    margin-right: 0px;
  }
  .bundle-name-logo {
    width: 85px;
  }
  .pupils-stats-card .pupils-content p {
    font-size: 15px;
    line-height: 18px;
    height: 45px;
  }
  .pupils-stats-card .pupils-content h3 {
    padding-right: 8px;
    line-height: 33.5px;
  }
  .pupils-stats mat-card {
    min-height: 120px;
    padding: 15px 15px;
  }
  section.table-section h4 {
    margin-bottom: 9px;
  }
  .checkbox-table .table thead th {
    font-size: 13px;
    line-height: 13.6px;
    padding: 8px 5px 4px;
    min-width: 100px;
  }
  .checkbox-table .table tr td {
    line-height: 16.6px;
    font-size: 13px;
    padding: 10px 5px;
  }
  .expactation-btn {
    font-size: 13px;
    padding: 6px 16px;
  }
  .employee-img-name {
    min-width: 180px;
  }
  .page-header-right .table-search-field {
    width: 100%;
    margin-bottom: 0 !important;
  }
  .dialog-header {
    padding-bottom: 13px;
    margin-bottom: 16px;
  }
  .dialog-section {
    width: 485px;
    padding: 20px 25px;
  }
  .pupils-info-details {
    width: 61%;
    padding: 0px 5px;
  }
  .pupils-name-list {
    width: 39%;
    padding: 20px 15px;
  }
  .pupils-detail-img img {
    width: 75px;
    height: 75px;
  }
  .pupils-detail-content h3 {
    font-size: 21px;
    line-height: 23.6px;
    margin-bottom: 14px;
  }
  .pupils-detail-content p {
    font-size: 14px;
    line-height: 16.3px;
  }
  .pupils-detail-content p span {
    width: 15px;
  }
  .pupils-info-content {
    font-size: 18px;
    line-height: 23.5px;
  }
  .pupils-info-icon {
    font-size: 14px;
    line-height: 14.6px;
  }
  .pupils-info-icon img {
    width: 37px;
    margin-right: 7px;
  }
  .upload-label {
    font-size: 13px;
    line-height: 14px;
  }
  .dashboard-inner-wrapper .profile-form .edit-profile-heading h3 {
    font-size: 21px;
  }
  .login-right-section .login-body h2 {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 10px;
  }
  .login-right-section .login-body {
    margin-left: 0;
    width: 410px;
    margin: 0 auto;
}
  .children-coment-content h3 {
    font-size: 19px;
    margin-bottom: 10px;
  }

  .children-coment-content p {
    font-size: 15px;
    margin-bottom: 10px;
  }

  .children-coment-content {
    margin-bottom: 13px;
  }

  .children-comment-section {
    padding: 25px 20px;
  }
  section.comment_section12 .filter-section mat-card {
    max-width: 31.82% !important;
    margin-bottom: 0px;
    margin-right: 10px !important;
  }

  section.comment_section12 .filter-section mat-card:last-child {
    display: none;
  }

  .comment-qa-list {
    padding-bottom: 5px;
  }

  .commented-user h6 {
    font-size: 16px;
  }

  .commented-user img {
    margin-right: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1070px) {
  .banner-inner {
    width: 78%;
    padding: 55px 0 40px;
  }
  .user-details-field .membership-table table td {
    padding: 15px 15px;
  }
  .user-details-field .membership-table table th {
    padding: 10px 15px;
  }
  .user-details-field p {
    font-size: 16px;
  }
  .user-profile p {
    overflow-wrap: break-word;
  }
  .bundle-book {
    padding: 30px 15px;
    min-height: 310px;
  }
  .bundle-ribon {
    margin-left: -22px;
  }
  .sidebar-header .header-row {
    padding: 0 20px;
  }
  .sidebar-header {
    height: 100 px;
  }
  .mat-sidenav-container.example-sidenav-container {
    min-height: calc(100vh - 95px);
  }
  mat-sidenav.mat-drawer.mat-sidenav {
    top: 95px;
  }
  .sidebar-content {
    padding-top: 95px;
  }
  .checkout-oder-list {
    padding: 12px 9px;
  }
  .place-order-section .btn {
    font-size: 16px;
    height: 45px;
  }
  .place-order-section {
    width: 100%;
  }
  .cart-detail .cart-table tr th {
    font-size: 14px;
  }
  .bundle-name-logo {
    top: -26px;
    right: -11px;
  }
  .bundle-img-section .bundle-img {
    margin-top: 35%;
  }
  .bundle-img-section .bundle-img img {
    max-height: 170px;
  }
  .menu-list ul li .table-search-field .table-search-box {
    margin: 0;
  }
  .pupils-stats-card .pupils-content p {
    font-size: 14px;
    line-height: 17px;
  }
  .pupils-stats {
    padding-bottom: 33px;
  }
  .custom-selectbox {
    width: 70px;
    padding: 5px 10px;
  }
  .inline-form-group .theme-btn {
    min-width: 142px;
  }
  .inline-form-group .form-control {
    max-width: 260px;
  }
  .pupils-detail-img img {
    width: 70px;
    height: 70px;
  }
  .pupils-detail-content h3 {
    font-size: 18px;
    line-height: 22.6px;
    margin-bottom: 14px;
  }
  .pupils-detail-content {
    margin-left: 13px;
  }
  .pupils-name-list {
    width: 30%;
    padding: 20px 12px;
  }
  .pupils-info-content {
    font-size: 17px;
    line-height: 20.5px;
  }
  
  .pupils-info-icon {
    font-size: 13px;
    line-height: 14.6px;
  }
  .sidebar-footer {
    padding: 20px 13px;
  }
  .pupils-recommendedbook h3 {
    line-height: 21px;
    font-size: 15px;
    margin-bottom: 8px;
    height: 43px;
  }
  section.comment_section12 .filter-section mat-card {
    max-width: 48.22% !important;
    margin-bottom: 13px;
    margin-right: 10px !important;
  }

  section.comment_section12 {
    padding: 25px 19px;
  }

  .comment-detail-section {
    padding: 25px 20px;
  }

  section.comment_section12 .filter-section {
    padding: 20px 20px;
  }

  .commented-book-img {
    width: 200px;
  }

  .commented-book-content {
    width: calc(100% - 200px);
  }

  .commented-book-content h3 {
    font-size: 18px;
    line-height: 25px;
    width: calc(100% - 95px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 970px) {
  .banner-content h1 {
    font-size: 29px;
  }
  .banner-content h3 {
    font-size: 24px;
  }
  li.big-checkout-btn {
    display: none;
  }
  .responsive-menu-btn {
    display: none !important;
  }
  .banner-inner {
    width: 85%;
  }
  .bundle-section mat-card {
    width: 100%;
  }
  .bundle-book h3 {
    font-size: 34px;
    line-height: 44px;
    height: auto;
  }
  .membership-wrapper {
    width: 95%;
  }
  .membership-list .memberlist-title {
    font-size: 18px;
  }
  .membership-list mat-card-header {
    height: 50px;
  }
  .membership-list .membership-price {
    font-size: 32px;
    line-height: 35px;
  }
  .membership-list .mat-grey-body {
    padding: 15px 10px !important;
  }
  .membership-list .plan-limit {
    /* margin-bottom: 10px; */
    margin-bottom: 0px;
  }
  .membership-list .plan-noti {
    font-size: 16px;
  }
  .membership-list mat-card-content ul li {
    font-size: 14px;
    padding: 9px 0px;
    line-height: 20px;
  }
  .bold-font,
  .membership-list mat-card-content ul li.bold-font {
    font-size: 16px;
  }
  .membership-btn {
    font-size: 15px !important;
    letter-spacing: 0;
  }
  .membership-list mat-card-actions {
    padding: 0 15px;
  }
  .shop-detail-section mat-card {
    width: 100%;
  }
  .bundle-big-img {
    padding: 40px 40px 60px;
  }
  .review-details {
    height: auto;
  }
  .revierclient-detial {
    position: unset;
    margin-top: 40px;
  }
  .profile-section.checkout-section mat-card {
    width: 100%;
  }
  .profile-pic {
    display: inline-block;
  }
  .upload-profile-pic {
    right: -8px;
  }
  .user-profile {
    padding: 50px 20px 30px;
  }
  .menu-list ul li a {
    padding: 5px 7px !important;
  }
  .theme-btn.checkout-btn {
    width: 120px !important;
    height: 40px !important;
  }
  .user-pic img {
    width: 40px !important;
    height: 40px !important;
  }
  .menu-list ul li {
    margin-right: 10px !important;
  }
  .inner-wrapper {
    width: 98%;
  }
  .dashboard-stats .dashboard-stats-row mat-card,
  .dashboard-stats .dashboard-stats-row mat-card:nth-child(3n) {
    flex: 1 1 49% !important;
    max-width: 49% !important;
    margin-right: 2%;
  }
  .dashboard-stats .dashboard-stats-row mat-card:nth-child(2n) {
    margin-right: 0;
  }
  .buy-book-row mat-card,
  .buy-book-row mat-card:nth-child(4n),
  .buy-book-row mat-card:nth-child(3n) {
    max-width: 49% !important;
    margin-right: 2% !important;
    flex: 1 1 49% !important;
  }
  .buy-book-row mat-card:nth-child(2n) {
    margin-right: 0% !important;
  }
  .buy-bundle-section.bundle-section .buy-bundle-row mat-card {
    max-width: 100% !important;
    flex: 1 1 100% !important;
    margin-right: 0 !important;
  }
  .buy-bundle-section .bundle-book h3 {
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 15px;
  }
  .mat-sidenav-container.example-sidenav-container {
    min-height: calc(100vh - 100px);
  }
  mat-sidenav.mat-drawer.mat-sidenav {
    top: 100px;
  }
  .sidebar-content {
    padding-top: 85px;
  }
  .book-shop-content h3 {
    font-size: 18px;
  }
  .book-shop-detail {
    padding: 40px 15px;
  }
  .dashboard-inner-wrapper {
    padding: 30px 15px 80px;
    width: 100%;
  }
  .checkout-product-block {
    max-width: 100% !important;
    flex: 1 1 100% !important;
  }
  .key-stage-card {
    max-width: 23.5% !important;
    flex: 1 1 23.5% !important;
    margin-right: 2% !important;
    margin-bottom: 2% !important;
  }
  .key-stage-card:nth-child(4n) {
    margin-right: 0 !important;
  }
  .order-datatable .table-book-field img {
    width: 40px;
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .orderList-field span {
    padding-right: 10px;
  }
  .orderList-field span:last-child {
    padding-right: 0;
  }
  .order-datatable .table-book-field,
  .order-datatable .table-book-field {
    min-width: 250px;
    flex-direction: column;
    align-items: flex-start;
    font-size: 14px;
  }
  .order-datatable .table-content {
    font-size: 14px;
    padding: 5px 8px !important;
  }
  .order-datatable {
    width: 750px;
    overflow-x: scroll;
  }
  .ordered-table {
    overflow-x: scroll;
  }
  .order-datatable .table-head {
    font-size: 14px;
    padding: 10px 8px !important;
  }
  .order-datatable .table-action-field {
    max-width: 70px;
    margin: auto;
  }
  .filter-section ul {
    padding: 20px 15px;
  }
  .filter-section {
    flex-direction: column !important;
  }
  .filter-section mat-card {
    max-width: 100% !important;
    flex: 1 1 100% !important;
  }
  .table-search-box {
    margin-bottom: 30px;
  }
  .table-search-field {
    padding: 0 20px;
  }
  .filter-title {
    width: 100%;
    margin-bottom: 14px !important;
  }
  .table-search-field {
    width: 100%;
  }
  .dashboard-profile-info-card {
    max-width: 100% !important;
    flex: 1 1 100% !important;
  }
  .cart-detail .cart-table {
    width: 100%;
  }
  .cartproduct-quantity input.qty {
    width: 44px;
    font-size: 16px;
    height: 34px;
  }
  .cartproduct-quantity .button-container .btn {
    width: 30px;
    padding: 0;
    height: 34px;
    line-height: 17px;
  }
  .checkout-section form {
    flex-direction: column !important;
  }
  .checkout-section form mat-card {
    max-width: 100% !important;
  }
  .contact-form-row {
    align-items: inherit !important;
  }
  .bundle-book {
    padding: 40px 15px 25px;
    margin: 0 10px;
    min-height: auto;
  }
  .bundle-img-section .bundle-img img {
    max-height: inherit;
  }
  .bundle-img-section .bundle-img {
    margin-top: 75px;
  }
  .bundle-name-logo {
    top: -32px;
    right: -8px;
  }
  .bundle-name-logo {
    width: 95px;
  }
  .membership-cancel button {
    width: auto;
    padding: 6px 26px;
    font-size: 14px;
  }
  .pupils-stats-card .pupils-img {
    position: inherit;
    margin-bottom: 15px;
    width: 100%;
    text-align: center;
  }
  .pupils-stats-card .pupils-img img {
    width: 60px;
    margin: auto;
  }
  .pupils-stats-card {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .pupils-stats-card .pupils-content h3 {
    margin-bottom: 0px;
    font-size: 23px;
    line-height: 22px;
    padding: 0;
  }
  .pupils-stats-card .pupils-content {
    width: 100%;
    text-align: center;
    padding: 0;
  }
  .pupils-stats mat-card {
    padding: 20px 11px;
  }
  .pupils-stats-card .pupils-content p {
    height: 35px;
    margin-bottom: 15px;
  }
  .sidenav-area ul li a {
    font-size: 16px;
    padding: 10px 10px;
  }
  .sidenav-area ul li a span {
    margin-right: 5px;
  }
  .checkbox-table tr td.table-action-field {
    padding-right: 35px;
  }
  .checboxTable.checkbox-table {
    overflow: hidden;
    overflow-x: auto;
  }
  .checboxTable.checkbox-table .table {
    width: 100%;
    min-width: 550px;
  }
  .checkbox-table .table tr td,
  .checkbox-table .table tr th {
    min-width: 110px;
    padding: 10px 7px;
  }
  th.mat-header-cell:first-of-type,
  td.mat-cell:first-of-type,
  td.mat-footer-cell:first-of-type {
    padding-left: 15px !important;
  }
  th.mat-header-cell:last-of-type,
  td.mat-cell:last-of-type,
  td.mat-footer-cell:last-of-type {
    padding-right: 15px !important;
  }
  .checkbox-table .table tr th.mat-column-student {
    min-width: 200px;
  }
  .checkbox-table .table thead th {
    font-size: 14px;
    line-height: 18.6px;
    padding: 10px 7px 3px;
  }
  .expactation-btn {
    font-size: 14px;
    padding: 5px 15px;
  }
  .pupils-table h3 {
    line-height: 22px;
    font-size: 17px;
  }
  .dialog-section-wrapper h4 {
    font-size: 20px;
    line-height: 25.4px;
  }
  .page-header-left button {
    margin-left: 15px;
  }
  .page-header-right .table-search-field .search-input {
    min-width: auto;
  }
  .page-header .page-header-left h2 {
    font-size: 20px;
  }
  .page-header-right .table-search-box {
    margin-bottom: 0;
  }
  .page-header-right .table-search-field {
    margin-bottom: 0px !important;
    width: 200px;
  }
  .sidebar-content {
    width: calc(100% - 225px) !important;
  }
  mat-sidenav.mat-drawer.mat-sidenav {
    max-width: 225px;
  }
  .pupils-name-list {
    width: 100%;
    padding: 20px 12px 10px;
    border-bottom: 1px solid #ececec;
    border-right: 0px;
    margin-bottom: 10px;
    justify-content: center;
  }
  .pupils-detail-card {
    flex-direction: column;
  }
  .pupils-info-details {
    width: 100%;
    margin-left: 0;
}
  .pupils-info-icon img {
    width: 40px;
    margin-right: 10px;
  }
  .pupils-info-icon {
    font-size: 15px;
    line-height: 17.6px;
  }
  .pupils-info-content {
    font-size: 18px;
    line-height: 20.5px;
  }
 
  .pupils-info-details ul li:nth-child(2n) {
    border-right: 0;
  }
  .pupils-info-details ul {
    padding-bottom: 10px;
  }
  .pupils-detail-img img {
    width: 75px;
    height: 75px;
  }
  .pupils-detail-content h3 {
    font-size: 22px;
    line-height: 24.6px;
    margin-bottom: 14px;
  }
  .pupils-detail-content p {
    font-size: 16px;
    line-height: 17.3px;
  }
  .login-right-section .right-menu p {
    font-size: 14px;
    line-height: 21px;
    margin-right: 14px;
  }
  .new-login-btn {
    width: 108px;
    height: 42px;
    font-size: 16px;
  }
  .login-left-img {
    padding: 20px 15px;
  }
  .login-right-section .login-body p {
    margin-bottom: 15px;
  }
  .login-section-row {
    padding: 30px 0;
  }
  .reset-password-section .reset-password-body {
    width: 466px;
    padding: 35px 28px 20px;
  }
  .two-column-input {
    flex-direction: column;
  }
  .two-column-input .form-group {
    width: 100%;
    margin-right: 0;
  }
  section.comment_section12 .filter-section mat-card {
    max-width: 47.22% !important;
    margin-bottom: 13px;
    margin-right: 10px !important;
  }

  section.comment_section12 .custom-select,
  .datepicker-section.input-full-width .form-control {
    padding: 10px 10px;
    font-size: 14px;
    height: 40px;
  }

  section.comment_section12 .filter-section {
    padding: 15px 20px 5px;
  }

  .comment-detail-section {
    padding: 25px 20px;
    flex-direction: column;
  }

  .commented-book-img {
    width: 160px;
  }

  .commented-book-content {
    width: 100%;
    padding-top: 20px;
    padding-left: 0;
  }
}

@media only screen and (min-width: 300px) and (max-width: 767px) {
  .login-body .form-group label {
    font-size: 16px;
}
  .login-left-sections-school p {
    font-size: 14px;
}

.login-left-sections-school {
    padding: 44px 49px;
    width: 287px;
    background-size: 100% 100%;
}
  .login-signup-wrapper:before {
    width: 100%;
    height: 68px;
}
.login-right-section .login-body form.example-form {
  padding: 0 0;
}
.click-login h6 {
  font-size: 15px;
}
.click-login h6 a {
  font-size: 15px;
}
.login-left-section-techer {
  padding: 38px;
  width: 288px;
  margin-bottom: 20px !important;
}

.login-left-section-techer h2 {
    font-size: 19px;
}

.login-left-img img {
    max-width: 17%;
}

.login-left-section-techer p {
  font-size: 14px;
}

.login-section-row {
  padding-bottom: 78px;
}
  .jas-list {
    margin-top: 30px;
}

.jas-list li h4 {
    padding-left: 8px;
    font-size: 13px;
}

.average-chart {
    margin-top: 50px;
}

.average-chart h2 {
    font-size: 20px;
}

.diff-word h2 {
    font-size: 20px;
}

.diff-word {
    margin-top: 50px;
}

.diff-word ul li {
    margin-right: 5px;
}

.diff-word ul li span {
    padding: 10px 10px;
    font-size: 14px;
}

.book-table {
    margin-top: 50px;
}

.book-table h2 {
    font-size: 20px;
}  
  .edit-reding-form input {
    width: 90%;
}

.edit-reding-form textarea {
    width: 90%;
    height: 100px;
}

.dialog-section.edit-reding-form {
    width: 100%;
}
.pupils-info-details {
  margin-left: 0;
}
  .sidebar-header .user-menu .menu-list ul li .table-search-field {
    display: none;
  }
  .inner-wrapper {
    width: 100%;
    padding: 0 15px;
  }
  .logo-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  header .header-row {
    padding: 0;
  }
  header {
    padding: 10px 0;
  }
  .menu-item {
    display: block;
  }
  .menu-list ul {
    flex-direction: column;
  }
  .menu-list ul li {
    margin: 0;
    padding: 5px 0;
  }
  .banner-inner {
    width: 100%;
    padding: 50px 20px 40px;
  }
  .banner-content h1 {
    font-size: 31px;
    line-height: 40px;
  }
  .banner-content h3 {
    font-size: 22px;
    line-height: 25px;
  }
  .banner-content a.banner-shop-btn {
    width: 130px;
    height: 45px;
    font-size: 14px;
  }
  .banner-img {
    text-align: center;
    margin-top: 40px;
    padding: 0;
  }
  .banner-img img {
    max-width: 100%;
  }
  .bundle-section {
    padding: 60px 0 30px;
  }
  .bundle-ribon {
    width: auto;
    padding: 15px 15px;
    max-width: 90%;
    height: auto;
    min-height: 56px;
    min-width: 270px;
    margin-left: -22px;
    margin-bottom: 17px;
  }
  .bundle-ribon p {
    font-size: 16px;
  }
  .bundle-book h3 {
    font-size: 28px;
    height: auto;
  }
  .bundle-book {
    padding: 30px 15px 15px;
    min-height: auto;
  }
  h4.bundle-price {
    font-size: 22px;
  }
  a.bundle-shop-btn {
    width: 150px;
    height: 45px;
  }
  .bundle-img {
    margin-top: 15px;
  }
  .menu-list ul li a.default-btn-css {
    margin-top: 13px;
  }
  .sidebar-header .menu-list ul li a.default-btn-css {
    margin-top: 0px;
  }
  .bundle-img img {
    max-width: 100%;
  }
  .membership-plan-section {
    padding: 50px 0;
  }
  mat-card.mat-heading {
    margin-bottom: 20px;
  }
  .membership-wrapper {
    width: 100%;
  }
  .mat-heading h2.heading {
    font-size: 32px;
  }
  .membership-list mat-card-header {
    height: 70px;
  }
  .membership-list .memberlist-title {
    font-size: 20px;
  }
  .membership-list mat-card-content {
    padding: 0px 15px;
  }
  .membership-list .membership-price {
    font-size: 45px;
  }
  .membership-list .plan-limit {
    font-size: 16px;
    /* margin-bottom: 15px; */
    margin-bottom: 0px;
  }
  .membership-list .plan-noti {
    font-size: 18px;
  }
  .membership-list .mat-grey-body {
    padding: 15px 15px 26px;
  }
  .membership-list mat-card-content ul li {
    font-size: 16px;
    padding: 15px 15px;
  }
  footer p {
    font-size: 16px !important;
    text-align: center;
    line-height: 25px !important;
  }
  .bundle-section mat-card {
    width: 100%;
    margin-right: 0 !important;
  }
  .menu-item.myCSSclass {
    display: none;
  }
  .menu-item {
    display: block;
  }
  .menu-item.user-menu .menu-list ul {
    display: flex;
    flex-direction: row;
  }
  .user-menu .menu-list ul li {
    margin-right: 15px;
  }
  .menu-left-list {
    position: absolute;
    top: 120px;
    left: 0;
    width: 100%;
    background: #fafafa;
    padding: 20px 0;
    padding-bottom: 30px;
    z-index: 99;
  }
  .user-menu .menu-list ul li {
    margin-right: 20px !important;
  }
  .menu-list ul li:last-child {
    margin-right: 0px !important;
  }
  .menu-item.user-menu {
    margin-left: 11px !important;
  }
  .responsive-menu-btn {
    background: #eaeaea !important;
  }
  .menu-list ul li {
    margin-right: 0 !important;
  }
  .login-body {
    max-width: 100%;
    padding: 30px 20px;
  }
  section.login-section,
  .signup-section,
  .forgot-password-section,
  .reset-password-section {
    padding: 60px 0;
  }
  section.login-section mat-card,
  .signup-section mat-card,
  .forgot-password-section mat-card,
  .reset-password-section mat-card {
    padding: 0;
  }
  .forgot-password-section .login-body {
    padding: 40px 20px 30px;
  }
  .reset-password-section .login-body {
    padding: 40px 20px;
  }
  .login-body p {
    font-size: 16px;
  }
  .login-body h2 {
    font-size: 25px;
  }
  .login-body .form-group .form-control,
  .login-body .form-group select.form-control {
    height: 45px !important;
    font-size: 15px;
    padding: 10px 10px;
  }
  .login-body .submit-btn {
    height: auto;
    font-size: 15px;
    padding: 7px 0;
}
  .bundle-big-img {
    padding: 20px 20px;
    width: 100%;
    padding-top: 110px;
  }
  .bundle-big-img .bundle-name-logo {
    top: 8px;
    right: 8px;
  }
  .shop-detail-section mat-card {
    width: 100%;
    padding: 0;
  }
  .bundle-full-description {
    padding-top: 30px;
  }
  .bundle-full-description h2 {
    font-size: 22px;
    line-height: 27px;
  }
  .bundle-full-description {
    padding-top: 30px;
  }
  .cart-wishlist-field {
    flex-wrap: wrap;
  }
  .cart-wishlist-field .quantity-btn {
    margin-bottom: 5px;
  }
  .cart-wishlist-field .btn-field {
    margin-bottom: 5px;
  }
  .bundle-briefing ul {
    flex-wrap: wrap;
  }
  .bundle-briefing ul li {
    width: 145px;
    padding: 0 10px;
  }
  .bundle-briefing ul li:last-child:after {
    display: block;
  }
  h2.section-title {
    font-size: 30px;
  }
  .review-details p {
    font-size: 16px;
    line-height: 26px;
  }
  .review-details {
    height: auto;
    padding: 30px 15px;
  }
  .revierclient-detial {
    position: unset;
    padding: 0;
  }
  .profile-section {
    padding: 60px 0;
    padding-top: 20px;
  }
  .user-profile {
    padding: 40px 20px;
  }
  .user-profile img {
    width: 140px;
    height: 140px;
  }
  .profile-pic {
    margin-bottom: 15px;
  }
  .user-profile h3 {
    font-size: 22px;
  }
  .user-profile p {
    font-size: 16px;
  }
  section.profile-section mat-card {
    padding: 0;
  }
  .user-details {
    padding-top: 30px;
  }
  section.profile-section .user-details mat-card {
    padding: 20px 15px;
  }
  .user-details-field h3 {
    font-size: 18px;
  }
  .user-details-field .membership-table {
    overflow: hidden;
    overflow-x: scroll;
  }
  .user-details-field .membership-table table {
    min-width: 350px;
  }
  .user-details-field .membership-table table td {
    font-size: 15px;
    padding: 15px 10px;
  }
  .user-details-field .membership-table table th {
    font-size: 16px;
    padding: 10px 10px;
  }
  .membership-btn-group {
    flex-direction: column;
  }
  .membership-btn-group a {
    display: inline-block;
  }
  .membership-btn-group .right-btn-group {
    margin-top: 10px;
  }
  .user-details-field p {
    font-size: 15px;
  }
  .payment-table-section {
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;
  }
  table.payment-table {
    min-width: 350px;
  }
  table.payment-table td {
    padding: 10px 5px;
    font-size: 12px;
  }
  table.payment-table td:last-child {
    width: auto;
  }
  table.payment-table td:first-child {
    padding-left: 15px;
  }
  .profile-form {
    padding-top: 30px;
  }
  .upload-profile-pic img {
    width: 40px;
    height: 40px;
  }
  .user-profile .profile-pic {
    display: inline-block;
  }
  .checkout-section .mat-inner {
    width: 100%;
  }
  .checkout-oder-list {
    padding: 12px 8px;
  }
  .checkout-oder-list ul li.order-book-img {
    width: auto;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .checkout-oder-list ul li {
    font-size: 14px;
    padding-right: 8px;
  }
  .checkout-order {
    padding: 20px 10px;
  }
  .checkout-oder-list ul {
    align-items: center;
  }
  .checkout-oder-list ul li:last-child {
    padding-right: 0;
  }
  .checkout-oder-list ul li img {
    width: 50px;
    height: 63px;
  }
  .checkout-order h3 {
    font-size: 22px;
  }
  .order-placed-btn {
    width: 200px;
    height: 50px;
    font-size: 16px;
  }
  section.profile-section.checkout-section {
    padding-top: 30px;
  }
  section.success-order-section {
    padding: 60px 0;
  }
  .success-order-field h1 {
    font-size: 33px;
    line-height: 40px;
    margin-bottom: 20px;
  }
  .success-order-field a {
    max-width: 100%;
  }
  .responsive-menu-btn {
    display: block !important;
  }
  .sidebar-header .header-row {
    padding: 0;
  }
  .sidebar-header .inner-wrapper {
    padding: 0 0px;
  }
  .sidebar-header {
    padding: 10px 12px;
    height: 78px;
  }
  .sidebar-header .user-menu .menu-list ul li img {
    width: 25px !important;
    height: 25px !important;
  }
  .sidebar-header .user-menu .menu-list .dashboard-profile-pic img {
    width: 40px !important;
    height: 40px !important;
    margin-right: 5px;
  }
  .dashboard-profile-pic span.down-soft {
    margin-left: 0px;
  }
  .sidebar-header .menu-list ul li:last-child {
    padding: 0 7px;
    padding-right: 0;
  }
  .sidebar-header .logo-item img {
    width: 100px;
  }
  mat-sidenav.mat-drawer.mat-sidenav {
    width: 80%;
    max-width: 260px;
  }
  .sidebar-header .user-menu .menu-list .dashboard-profile-pic .user_name {
    display: none;
  }
  .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
    top: -14px;
    right: -8px;
  }
  .sidebar-header .example-is-mobile .example-toolbar {
    position: unset !important;
  }
  .sidebar-header .user-menu .menu-list ul li {
    margin-right: 15px !important;
  }
  .sidebar-header .user-menu .menu-list ul li:last-child {
    margin-right: 0 !important;
  }
  .sidebar-content {
    padding: 0px 15px;
    padding-top: 80px;
  }
  .page-header h2 {
    font-size: 23px;
  }
  .page-header p {
    font-size: 13px;
  }
  .dashboard-stats mat-card {
    height: auto;
    margin-right: 0;
    padding: 20px 20px !important;
  }
  .dashboard-stats-card .statscard-content p {
    margin-bottom: 20px;
  }
  .dashboard-stats-card .statscard-img {
    width: 40%;
  }
  .dashboard-stats-card .statscard-img img {
    max-width: 100%;
  }
  .dashboard-stats-card .statscard-content {
    width: 70%;
  }
  .buy-book-section mat-card {
    padding: 30px 15px;
    margin-right: 0;
  }
  .buy-bundle-section .bundle-book h3 {
    font-size: 27px;
  }
  .dashborad-full-wrapper {
    padding: 40px 15px 60px;
  }
  .dashborad-full-wrapper .bundle-briefing ul li {
    width: 150px;
    padding: 0 10px;
    max-width: 50%;
  }
  .book-shop-section mat-card {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .book-shop-detail {
    padding: 30px 20px;
    flex-direction: column-reverse;
  }
  .book-shop-content h3 {
    font-size: 25px;
    margin-bottom: 0;
  }
  .book-shop-img img {
    width: auto;
    max-width: 120px;
  }
  .book-shop-img {
    margin-bottom: 30px;
  }
  .dashboard-inner-wrapper {
    width: 100%;
    padding: 30px 10px 50px;
  }
  .dashboard-profile-section {
    padding: 0 !important;
  }
  .book-shop-img {
    padding: 0;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
  .book-shop-img img {
    max-width: 100%;
  }
  .key-stage-content {
    padding-top: 15px;
  }
  .key-stage-detail {
    height: auto;
  }
  .title-date .back-btn {
    margin-left: 15px;
    margin-top: 15px;
  }
  .title-date {
    flex-direction: column;
  }
  .back-btn {
    width: 90px;
    padding: 0;
  }
  .filter-section ul {
    padding: 20px 15px;
    flex-wrap: wrap;
  }
  .filter-title {
    margin-right: 15px !important;
    width: 100%;
  }
  .contact-form-row {
    align-items: inherit !important;
  }
  .filter-section .filter-button,
  .filter-section .custom-select,
  .filter-section .dateRange {
    padding: 10px 10px;
    font-size: 14px;
    width: 120px;
  }
  .order-datepicker {
    width: 120px;
  }
  .table-search-box {
    margin-bottom: 20px;
  }
  .table-search-field {
    padding: 0 20px;
    width: 100%;
    max-width: 290px;
  }
  .filter-section li.or-seperate {
    font-size: 17px;
    width: 100%;
    margin: 5px 0 10px;
    padding: 0 120px;
    display: block;
  }
  .filter-section {
    flex-direction: column !important;
  }
  .filter-section mat-card {
    max-width: 100% !important;
    flex: 1 1 100% !important;
  }
  .order-datatable .table-book-field img {
    width: 40px;
    margin-right: 7px;
    margin-bottom: 10px;
  }
  .order-datatable .table-book-field,
  .order-datatable .table-book-field {
    min-width: 220px;
    flex-direction: column;
    align-items: flex-start;
    font-size: 14px;
  }
  .order-datatable .table-content {
    font-size: 14px;
    padding: 5px 8px !important;
  }
  .order-datatable {
    width: 750px;
    overflow-x: scroll;
  }
  td.cart-img.order-details-title-img {
    display: flex;
    align-items: center;
  }
  td.cart-img.order-details-title-img img {
    padding-right: 7px;
  }
  .ordered-table {
    overflow-x: scroll;
  }
  .order-datatable .table-head {
    font-size: 14px;
    padding: 10px 8px !important;
  }
  .order-datatable .table-action-field {
    max-width: 70px;
    margin: auto;
  }
  .subcat-title .subcat-title-left h3,
  .teacher-title h3 {
    font-size: 21px;
    line-height: 32px;
  }
  .subcat-title-right {
    flex-direction: column;
    align-items: flex-end;
  }
  .radio-btn-field {
    width: 100%;
    margin: 10px 0 20px;
    text-align: right;
  }
  .subcat-title-right .table-search-field {
    width: 100%;
    max-width: 300px;
  }
  .cart-detail {
    overflow: scroll;
  }
  .cart-detail .cart-table {
    width: 600px;
  }
  .cart-table tr td {
    padding: 15px 10px;
  }
  .cart-table .cart-product-detail {
    flex-direction: column;
  }
  .cart-table .cart-product-detail .cart-img {
    margin-bottom: 10px;
  }
  .cart-table .cart-product-detail .cart-img img,
  .cart-img img {
    max-width: 60px;
  }
  .cart-table .cart-product-detail .cart-content {
    padding-left: 0px;
  }
  .cart-table h4 {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .cart-detail .cart-table tr th {
    font-size: 15px;
    padding: 10px 5px;
    vertical-align: middle;
  }
  .place-order-section {
    padding: 35px 20px;
    max-width: 309px;
    margin-left: auto;
  }
  .place-order-section li.total-title {
    font-size: 16px;
  }
  .place-order-section li.total-price span {
    font-size: 22px;
  }
  .cart-detail .cart-table tr th:nth-child(3),
  .cart-detail .cart-table tr th:nth-child(4) {
    width: 110px;
  }
  .cart-detail .cart-table tr th:nth-child(5) {
    width: 80px;
  }
  .cartproduct-quantity input.qty {
    width: 40px;
    font-size: 18px;
  }
  .place-order-section .btn {
    height: 55px;
    font-size: 18px;
  }
  .checkout-section form {
    flex-direction: column !important;
  }
  .checkout-section form .mat-inner {
    max-width: 100% !important;
  }
  .checkout-oder-list ul li.order-book-img span {
    padding: 0;
  }
  .checkout-oder-list ul li img {
    margin-bottom: 5px;
  }
  .user-menu .menu-list ul li:last-child {
    margin-right: 0 !important;
  }
  .error-section {
    min-height: auto;
    padding: 40px 0px !important;
    height: auto;
    overflow: auto;
  }
  .error-img,
  .error-logo,
  .error-content {
    position: unset;
    margin-bottom: 35px;
  }
  .error-img img {
    max-width: 100%;
  }
  .error-field p {
    font-size: 28px;
    margin-bottom: 17px;
    line-height: 32px;
  }
  .error-section .error-field {
    display: block;
  }
  .page-back-btn {
    width: auto !important;
    padding: 13px 15px !important;
    font-size: 18px;
    height: auto !important;
    line-height: 24px;
  }
  mat-sidenav.mat-drawer.mat-sidenav {
    transform: translate3d(-0%, 0, 0) !important;
    visibility: visible !important;
    transition: all 0.3s;
  }
  mat-sidenav.mat-drawer.mat-sidenav.myCSSclass {
    transform: translate3d(-100%, 0, 0) !important;
    visibility: hidden !important;
    transition: all 0.3s;
  }
  mat-sidenav.mat-drawer.mat-sidenav {
    top: 85px;
  }
  .book-shop-content {
    width: 100%;
    text-align: center;
  }
  .sidenav-area {
    padding: 0px 0;
  }
  .key-stage-detail {
    padding: 35px 18px;
  }
  .key-stage-content h3 {
    font-size: 20px;
  }
  .buy-book-content h3 {
    font-size: 16px;
    line-height: 22px;
  }
  .custom-pagination-btn .mat-button-base {
    width: 30px;
    height: 30px;
    line-height: 15px;
  }
  .mat-paginator-range-label {
    margin: 0px 25px 0 15px !important;
  }
  .mat-paginator {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  a.add-adress-redbtn {
    font-size: 15px;
  }
  .user-details-field h3 span {
    padding-left: 5px;
  }
  h3.address-heading {
    font-size: 16px;
  }
  .user-billing-address.user-details-field {
    padding: 16px 12px;
  }
  .school-login-section,
  .school-signup-section,
  .school-forgot-password {
    margin-top: 85px;
  }
  .menu-list ul li a {
    font-size: 14px;
  }
  .dashboard-profile-section.student-profile-section {
    padding: 15px 0 !important;
  }
  .mat-drawer-backdrop.mat-drawer-shown {
    visibility: inherit;
    display: none;
  }
  .subcat-book-section .buy-book-content h3 {
    height: auto;
  }
  .order-detail-left {
    max-width: 100% !important;
    flex: 1 1 100% !important;
  }
  .order-detail-right {
    max-width: 100% !important;
    flex: 1 1 100% !important;
    margin-top: 50px;
  }
  .order-billing-details {
    padding: 27px 15px;
  }
  .order-billing-details p {
    font-size: 15px;
  }
  .order-detail-field {
    margin-right: 0px;
  }
  h3.order-detail-id {
    font-size: 17px;
  }
  .order-detail-section h3 {
    font-size: 17px;
  }
  .order-detail p {
    font-size: 15px;
  }
  .order-detail-table table th {
    font-size: 15px;
    padding: 10px 8px;
  }
  .order-detail-table table td {
    font-size: 15px;
    padding: 15px 8px;
  }
  .order-detail-pricing .checkout-pricing p {
    font-size: 15px;
  }
  .checkout-pricing ul li {
    padding: 15px 0;
  }
  .checkout-pricing ul li p.total-price {
    font-size: 16px;
  }
  .order-billing-details .address-field {
    margin-bottom: 20px;
  }
  .order-detail-table {
    overflow: hidden;
    overflow-x: scroll;
  }
  .order-detail-table table {
    min-width: 520px;
  }
  .bold-font,
  .membership-list mat-card-content ul li.bold-font {
    font-size: 16px;
  }
  .bundle-name-logo {
    top: inherit;
    bottom: -9px;
    width: 85px;
    right: -8px;
  }
  .bundle-img-section .bundle-img {
    margin-top: 15px;
    margin-bottom: 80px;
  }
  .bundle-img-section .bundle-img img {
    max-height: inherit;
  }
  .update-btn {
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
  }
  .pupils-stats-card .pupils-img {
    position: inherit;
    margin-bottom: 18px;
  }
  .pupils-stats-card {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .pupils-stats-card .pupils-content h3 {
    line-height: 30.5px;
    margin-bottom: 15px;
    font-size: 26px;
  }
  .pupils-stats-card .pupils-content p {
    font-size: 15px;
    line-height: 19.3px;
  }
  section.table-section h4 {
    line-height: 20.6px;
    font-size: 17px;
    margin-bottom: 10px;
  }
  section.table-section mat-card {
    padding: 0;
  }
  .checkbox-table tr td.table-action-field {
    padding-right: 15px;
  }
  .checkbox-table .table tbody tr td:first-child {
    padding-left: 15px;
  }
  .table-selectbox {
    width: 55px;
  }
  .table-noHead tr td {
    padding: 10px 4px;
    font-size: 13px;
  }
  .class-shortname {
    width: 32px;
    height: 32px;
    font-size: 13px;
  }
  .checkbox-table .table tr .table-action-field img {
    width: 41px !important;
  }
  .checkbox-table .table tr td,
  .checkbox-table .table tr th {
    padding: 10px 5px;
  }
  .table-action-field {
    width: 70px;
    text-align: center !important;
  }
  .checkbox-table .table thead th {
    font-size: 13px;
    line-height: 13.6px;
    padding: 10px 5px 5px;
  }
  .checkbox-table tr td {
    line-height: 15.6px;
    font-size: 13px;
  }
  .checboxTable.checkbox-table {
    overflow: hidden;
    overflow-x: auto;
  }
  .checboxTable.checkbox-table .table {
    width: 100%;
  }
  .checkbox-table .table tr td,
  .checkbox-table .table tr th {
    min-width: 95px;
  }
  .employee-img-name {
    width: 165px;
  }
  .expactation-btn {
    padding: 5px 12px;
    font-size: 13px;
  }
  .checkbox-table .table tbody tr td.table-selectbox {
    min-width: 40px;
  }
  .action-dropmenu .order-action-btn {
    font-size: 14px;
  }
  .custom-selectbox {
    width: 70px;
    padding: 5px 10px;
  }
  .pupils-table h3 {
    line-height: 22px;
    font-size: 17px;
  }
  .page-header.flex-header-section {
    flex-direction: column;
    align-items: flex-start;
  }
  .page-header-left {
    margin-bottom: 13px;
  }
  .page-header-left button {
    margin-left: 13px;
    height: 40px;
  }
  .table-responsive-section .checboxTable.checkbox-table .table {
    min-width: 370px;
  }
  .table-shortname {
    min-width: 120px;
  }
  .dialog-section {
    max-width: 100%;
    padding: 15px 10px;
  }
  .dialog-section-wrapper h4 {
    font-size: 20px;
    line-height: 25.4px;
  }
  .dialog-header {
    padding-bottom: 6px;
    margin-bottom: 13px;
  }
  .dialog-section-wrapper .form-group label {
    font-size: 14px;
    line-height: 18.6px;
  }
  .dialog-section-wrapper .form-group .form-control {
    font-size: 13px;
  }
  .form-button-group .form-btn {
    height: 42px;
    line-height: 13.9px;
    font-size: 13px;
  }
  .add-teacher-form h5 {
    font-size: 14px;
    line-height: 18.6px;
    margin-bottom: 13px;
  }
  .inline-form-group .form-control {
    max-width: 220px;
    height: 45px;
    font-size: 13px;
    padding: 6px 10px;
  }
  .inline-form-group .theme-btn {
    height: 45px;
    margin-left: 6px;
    padding: 0 10px;
    font-size: 12px;
    min-width: 100px;
  }
  .sidebar-content {
    width: 100% !important;
  }
  .pupils-detail-card {
    flex-direction: column;
  }
  .pupils-name-list {
    width: 100%;
    padding: 20px 15px 12px;
    border-bottom: 1px solid #ececec;
    border-right: 0;
    justify-content: center;
  }
  .pupils-detail-img img {
    width: 60px;
    height: 60px;
  }
  .pupils-detail-content {
    margin-left: 15px;
  }
  .pupils-detail-content h3 {
    font-size: 21px;
    line-height: 26.6px;
    margin-bottom: 14px;
  }
  .pupils-detail-content p {
    font-size: 15px;
    line-height: 18.3px;
    margin-bottom: 4px;
  }
  .pupils-detail-content p span {
    width: 16px;
  }
  .pupils-info-details {
    width: 100%;
    padding: 11px 0px 11px;
  }
  .pupils-info-details ul {
    flex-direction: column;
    justify-content: center;
  }
  .pupils-info-details ul li {
    width: 100% !important;
    padding: 10px 15px;
    margin: 5px 0;
    border-right: 0;
}
.pupils-info-content {
  font-size: 20px;
  line-height: 23.5px;
  word-break: break-all;
}
  .pupils-info-icon img {
    width: 40px;
  }
  .row.membership-tab-section {
    margin: 0 !important;
  }
  .reset-password-section .login-body {
    padding: 45px 13px 15px;
  }
  .dashboard-profile-section.student-profile-section.reset-password-section {
    padding: 0 !important;
  }
  .login-body.reset-password-body h2 {
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 15px;
  }
  .profile-pic .upload-profile-pic {
    right: 29px;
  }
  .edit-profile-heading {
    margin-bottom: 20px;
  }
  .two-column-input {
    flex-direction: column;
  }
  .two-column-input .form-group {
    width: 100%;
    margin-right: 0;
  }
  .select-charity-section mat-radio-button {
    width: 100%;
    margin-right: 0;
  }
  .select-charity-list {
    flex-direction: column;
  }
  .select-charity-section mat-radio-button {
    height: auto;
    padding: 20px 12px;
  }
  .charity-list-content {
    margin-top: 10px;
    margin-left: 0;
  }
  .charity-list-content h5 {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 10px;
  }
  .charity-list-content p {
    font-size: 14px;
    line-height: 18px;
  }
  .know-btn-area {
    position: unset;
  }
  .dashboard-inner-wrapper .profile-form .edit-profile-heading h3 {
    font-size: 20px;
    margin-bottom: 15px;
  }
  .form-group.new-form-btn button {
    width: 110px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }
  .dashboard-inner-wrapper.dashborad-full-wrapper.edit-school-wrapper {
    padding: 30px 10px 15px;
  }
  .login-left-section .logo-item img {
    max-width: 90px;
  }
  .loginSignup-header-row {
    flex-wrap: nowrap;
    flex-direction: row !important;
  }
  /* mat-card.mat-card.mat-focus-indicator.mat-left-card {} */
  .loginSignup-header-row mat-card {
    max-width: 50%;
  }
  .login-signup-wrapper .mat-left-card {
    background: transparent !important;
  }
  .login-signup-wrapper {
    background: transparent;
    background-color: #f0f0e6;
}
  .login-signup-wrapper .inner-wrapper {
    padding: 0 !important;
  }
  .login-right-section {
    padding-right: 0px;
  }
  .right-menu {
    flex-direction: column;
  }
  .login-right-section .right-menu p {
    margin-right: 0;
    margin-bottom: 5px;
    font-size: 13px;
    line-height: 15px;
  }
  .login-right-section .right-menu p {
    display: none;
  }
  .new-login-btn {
    width: 100px;
    height: 45px;
    font-size: 15px;
  }
  .login-left-img {
    padding: 0px 0px;
    margin-bottom: 20px;
    margin-top: 7px;
}
.login-signup-wrapper .mat-right-card {
  margin: 0 10px;
}
  .login-right-section .login-body h2 {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 15px;
    height: 68px;
  }
  .login-right-section .login-body p {
    margin-bottom: 20px;
  }
  .login-right-section .login-body {
    width: 100%;
    margin-left: 0;
  }
  .buybookshop-ribbon {
    margin-left: -27px;
  }
  .recommendedbook-list-img {
    height: 200px;
  }
  .pupils-recommendedbook h3 {
    line-height: 22px;
    font-size: 16px;
    margin-bottom: 8px;
    height: 45px;
  }
  .children-comment-section {
    padding: 20px 15px;
  }

  .children-coment-content h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .children-coment-content p {
    margin-bottom: 10px;
    font-size: 15px;
  }

  .children-coment-content {
    margin-bottom: 13px;
  }
  section.comment_section12 {
    padding: 0px 0px;
    background: transparent;
    box-shadow: none;
  }

  .comment-detail-section {
    flex-direction: column;
    width: 100%;
    padding: 20px 15px;
  }

  .commented-book-img {
    max-width: 100%;
    width: 200px;
    text-align: center;
    margin: auto;
  }

  .commented-book-content {
    width: 100%;
    padding: 0;
    padding-top: 15px;
  }

  .commented-book-title {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .commented-book-content h3 {
    width: 100%;
    text-align: left;
    font-size: 20px;
    line-height: 25px;
    margin-top: 6px;
    margin-bottom: 10px;
  }

  .commented-book-title h5 {
    width: 100%;
    text-align: right;
    font-size: 14px;
    margin: 0;
  }

  .commented-user {
    margin-bottom: 15px;
  }

  .commented-user img {
    margin-right: 10px;
  }

  .commented-user h6 {
    font-size: 15px;
    font-weight: 500;
    line-height: 21px;
  }

  .comment-qa-list h5 {
    font-size: 16px;
    line-height: 21px;
  }

  .comment-qa-list p {
    font-size: 15px;
    margin-bottom: 9px;
    line-height: 19px;
  }

  .comment-qa-list ul li {
    padding: 7px 17px;
    font-size: 15px;
    font-weight: 500;
    line-height: 19px;
    margin-right: 7px;
    margin-bottom: 7px;
  }
  section.comment_section12 .filter-section {
    padding: 0px 0px;
  }

  section.comment_section12 .filter-section mat-card:last-child {
    display: none;
  }

  section.comment_section12 .custom-select,
  .datepicker-section.input-full-width .form-control {
    width: 100%;
    height: 40px;
    padding: 6px 10px;
    font-size: 15px;
  }
}
@media only screen and (min-width: 300px) and (max-width: 390px) {
  .recommendedbook-list-img {
    height: 160px;
  }
}
@media only screen and (min-width: 300px) and (max-width: 350px) {
  .login-right-section .right-menu p {
    display: none;
  }
  .sidebar-header .user-menu .menu-list ul li {
    margin-right: 12px !important;
  }
  .user-menu .menu-list ul li {
    margin-right: 15px !important;
  }
  .menu-item.user-menu {
    margin-left: 5px !important;
  }
  .sidebar-header .header-row {
    padding: 0;
  }
  .sidebar-header {
    height: 85px;
  }
  .sidebar-header .user-menu .menu-list ul li img {
    width: 25px !important;
    height: 25px !important;
  }
  .sidebar-header .user-menu .menu-list .dashboard-profile-pic img {
    width: 40px !important;
    height: 40px !important;
    margin-right: 5px;
  }
  .dashboard-profile-pic span.down-soft {
    margin-left: 0px;
  }
  .sidebar-header .menu-list ul li:last-child {
    padding: 0 7px;
    padding-right: 0;
  }
  .sidebar-header .logo-item img {
    width: 100px;
  }
  mat-sidenav.mat-drawer.mat-sidenav {
    width: 80%;
    max-width: 260px;
  }
  .sidebar-header .user-menu .menu-list .dashboard-profile-pic .user_name {
    display: none;
  }
  .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
    top: -14px;
    right: -8px;
  }
  .pupils-stats-card .pupils-img {
    width: 100%;
    text-align: center;
  }

  .pupils-stats-card .pupils-img img {
    width: 60px;
  }

  .pupils-stats-card .pupils-content {
    text-align: center;
    width: 100%;
    padding-left: 0;
  }

  .pupils-stats-card .pupils-content p {
    height: auto;
    margin-bottom: 20px;
  }

  .pupils-stats-card .pupils-content h3 {
    margin: 0;
    padding: 0;
  }

  .sidenav-area ul li a {
    padding: 10px 13px;
  }
  .add-teacher-section {
    margin-bottom: 26px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .login-left-sections-school {
    max-width: 363px;
    padding: 70px;
}

.login-left-sections-school p {
    font-size: 14px;
}
  .login-left-img {
    padding: 0;
}
.login-left-section-techer p {
  font-size: 15px;
}

.login-left-section-techer h2 {
  font-size: 16px;
}

.login-left-img img {
  max-width: 20%;
}

.login-left-section-techer {
  padding: 50px;
  width: 335px;
}
.login-signup-wrapper:before {
  width: 47%;
  height: 83px;
}

.login-right-section .login-body h2 {
  text-align: center;
  font-size: 20px;
  height: 65px;
}

.login-right-section .login-body form.example-form {
  padding: 0 10px;
}

.login-body p {
  font-size: 14px;
}

.login-body .form-group .form-control, .login-body .form-group select.form-control {
  height: 50px;
  font-size: 16px;
}

.click-login h6 {
  font-size: 16px;
}

.click-login h6 a {
  font-size: 16px;
}

.login-body .submit-btn {
  padding: 8px 0px;
  font-size: 16px;
}

}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .login-left-sections-school {
    max-width: 443px;
    padding: 70px;
}

.login-left-sections-school p {
    font-size: 16px;
}
  .login-left-section-techer p {
    font-size: 18px;
}

.login-left-section-techer {
    width: 454px;
    padding: 84px 85px 96px 31px;
}

.login-left-section-techer h2 {
    font-size: 25px;
}

.login-left-img img {
    max-width: 15%;
}

.login-signup-wrapper:before {
    width: 47%;
    height: 110px;
}

.login-right-section .login-body h2 {
    height: 90px;
    font-size: 22px;
}
.login-body .form-group .form-control, .login-body .form-group select.form-control {
  height: 50px;
  font-size: 16px;
}

.login-body .submit-btn {
  padding: 10px 0px;
  font-size: 16px;
}

.click-login h6 {
  font-size: 16px;
}

}

@media only screen and (min-width: 1201px) and (max-width: 1365px) {
  .login-left-sections-school {
    max-width: 478px;
    padding: 79px;
}

.login-left-sections-school p {
    font-size: 18px;
}
  .login-left-section-techer {
    padding: 89px 53px 106px 55px;
    max-width: 528px;
}

.login-left-section-techer p {
    font-size: 25px;
}

.login-left-section-techer h2 {
    font-size: 25px;
}

.login-left-img img {
    max-width: 14%;
}

.login-signup-wrapper:before {
    width: 47%;
    height: 130px;
}

.login-right-section .login-body h2 {
    height: 100px;
    font-size: 29px;
}

.login-body .form-group .form-control, .login-body .form-group select.form-control {
    height: 55px;
    font-size: 16px;
}

.click-login h6 {
    font-size: 16px;
}

.click-login h6 a {
    font-size: 16px;
}

.login-body .submit-btn {
    padding: 10px 0px;
    font-size: 18px;
}

.login-body p {
    font-size: 18px;
}

}
/* Responsive Code */

@media only screen and (min-width: 1366px) and (max-width: 1900px) {
  .click-login h6 a {
    font-size: 16px;
}
  .login-left-sections-school {
    padding: 123px;
    max-width: 645px;
}

.login-left-sections-school p {
    font-size: 18px;
}

.login-right-section .login-body h2 {
    height: 120px;
}

.login-body .form-group .form-control, .login-body .form-group select.form-control {
    height: 55px;
    font-size: 16px;
}

.login-body .submit-btn {
    padding: 10px 0px;
    font-size: 16px;
}

.click-login h6 {
    font-size: 15px;
}
.login-left-section-techer {
  padding: 88px 84px 94px 61px;
  max-width: 525px;
}

.login-left-section-techer p {
  font-size: 22px;
}

.login-left-section-techer h2 {
  font-size: 24px;
}

.login-left-img img {
  max-width: 14%;
}
  .login-signup-wrapper:before {
    height: 146px;
}
  .pupils-name-list {
    width: 30% !important;
    padding: 20px 20px;
}
.pupils-info-details ul li {
  width: 48% !important;
  padding: 24px 20px;
  margin-bottom: 7px;
}
.average-chart {
  margin-top: 50px;
}

.average-chart h2 {
  font-size: 20px;
  margin-bottom: 20px;
}

.diff-word {
  margin-top: 50px;
}

.diff-word h2 {
  font-size: 20px;
  margin-bottom: 20px;
}

.diff-word ul li span {
  padding: 10px 20px;
}

.diff-word ul li {
  margin-bottom: 9px;
  margin-right: 5px;
}

.book-table {
  margin-top: 50px;
}

.book-table h2 {
  font-size: 20px;
  margin-bottom: 20px;
}

}
app-loader {
  width: 100%;
}

.table-qr .mat-sort-header-container {
   
  align-items: center;
  justify-content: center;
}

.table-qr .th_left .mat-sort-header-container {
  justify-content: flex-start;
}

.text_captialize {
  text-transform: capitalize;
}
.allLabel .mat-checkbox-label {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
  color: rgba(0,0,0,.87);
}



.mat-select-panel-5 {
  min-width: unset !important;
  margin-left: 24px;
  width: 107% !important;
  max-width: unset !important;
  overflow-y: scroll !important;
  margin-top: -2px;
}

.allLabel .mat-checkbox-inner-container {
    width: 16px !important;
    height: 16px !important;
    border: 0;
    margin-left: 4px;
}

.allLabel .mat-checkbox-frame {
    border: 2px solid;
}

/*********profile start*********/
.school-all-tabs .login-body.reset-password-body {
  margin-top: 25px;
}

.school-all-tabs .close-button {
  display: none;
}

.school-all-tabs .login-body.reset-password-body h2 {
  font-size: 27px;
}

.school-all-tabs .login-body .form-group label {
  font-size: 14px;
  font-family: "Avenir Book";
  font-weight: 600;
}

.school-all-tabs .login-body .form-group .form-control, .school-all-tabs .login-body .form-group select.form-control {
  color: #161616;
  font-family: "Avenir LT Std";
  font-weight: 500;
  font-size: 15px;
  height: 45px;
  border-radius: 3px;
  border: 1px solid #d1d1d1;
}

.school-all-tabs .login-body .submit-btn {
  border-radius: 4px;
}

.school-all-tabs .dashboard-profile-section {
  background-color: transparent;
}

/*********profile end*********/
.mat-stepper-horizontal-line {
  display: none !important;
}
.mat-horizontal-stepper-header {
  background-color: #ddd;
  color: #FFFFFF;
  height: 40px !important;
  margin-right: 10px;
}
.mat-step-text-label {
  color: #003430;
  font-family: 'fsme-regular';
  font-size: 15px;
  font-weight: 400;
}
.mat-horizontal-stepper-header-container {
  margin-left: 22px;
}



.filter-book .mat-form-field-infix {
  background-color: white;
  color: black;
  border: 1px solid #ddd;
  padding: 7px 9px;
}

/* dfff */

.transactions-stepper .mat-horizontal-stepper-header {
  border-radius: 4px;
  border: 1px solid var(--Color-Border-White-Coffee, #E0E0D7);
  background: var(--Color-Neutral-White, #FFF);
}
.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: transparent;
}
.transactions-stepper .mat-horizontal-stepper-header[aria-selected="true"] {
  background: #5AAF96;
  color: #fff !important;
}

.transactions-stepper .mat-horizontal-stepper-header[aria-selected="true"] .mat-step-text-label {
  color: #fff;
}

.add-pupils-form-bg .parents-row mat-select {
  padding: 11px 20px !important;
  width: 252px !important;
  background-color: #ffff;
  font-size: 16px;
}

.pupils-name-list.pupils-name-list.new-pupils-name-list {
  width: 35%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 35px 30px !important;
}
.text-captial {
  text-transform: capitalize !important;
}

.fixed-bottom-btn.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  padding: 0;
  line-height: normal;
  
}

.fixed-bottom-btn.mat-button-toggle-standalone.mat-button-toggle-appearance-standard {
  border: 0;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #6478a5;
  color: #fff;
  border-radius: 50%;
}